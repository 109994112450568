import React, { useState } from 'react';
import { TypeMenuItem } from '../PageTemplate';
import styled from 'styled-components';
import { Icon } from 'antd';
import { TypeSubMenu } from './SubMenu';
import { colors } from '@digi-tim-19/theme';
import { clearTourClassName, TourSection } from 'providers/TourProvider';

const stylesMap: { [key: number]: string } = {
  1: `border-radius: 4px; 
  padding: 8px;
  flex: 1;
  min-width: 188px;
  max-width: 188px;
  
  & :last-child {
    border: 0;
  }
  
  & p {
    font-family: TimBold;
    font-size: 14px;
    color: ${colors.blue};
    font-weight: bold;
    margin: 0px;
  
  }`,
  2: `border-bottom: solid 1px #D3D3D3;
    & p {
      padding: 8px 0 6px 0;
      font-size: 12px;
      font-weight: bold;
      color: #535353;
      font-family: TimMedium;
    }
    `,
  3: `
  & p {
      font-family: TimRegular;
      padding: 0;
      font-size: 12px;
      font-weight: 600;
    }
   `,
  4: `
    & p {
      font-size: 12px;
      font-weight: 400;
      font-family: TimLight;
    }
   `
};

const SubMenuWrapper = styled.div<{
  isOpen: number;
  haveChildren?: boolean;
}>`
  font-family: Segoe UI;
  display: flex;
  flex-direction: column;
  position: relative;

  .clickable:hover {
    color: ${colors.blue};
  }

  & p {
    font-size: 16px;
    display: block;
    justify-content: space-between;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    &:hover {
      color: ${({ isOpen, haveChildren }) =>
        [2, 3].includes(isOpen) && haveChildren ? '#535353' : colors.blue};
    }

    & > div {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  ${({ isOpen }) => stylesMap[isOpen] ?? ''};
`;

const ContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function hasChildren(item: TypeMenuItem) {
  return item.children && item.children.length > 0;
}

export const SubMenuItem = ({
  selectedItem,
  redirecionarLink
}: TypeSubMenu) => {
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(true);

  const renderSubMenuItem = (item: TypeMenuItem) => {
    if (item.children && item.children.length > 0) {
      return subMenuOpen && item.nivel === 4 ? (
        ''
      ) : (
        <>
          {item.children.map((currentSubitem, index) => (
            <div>
              <TourSection
                selector={`tour-${clearTourClassName(currentSubitem._id)}`}
              />
              <SubMenuWrapper
                key={index}
                isOpen={item.nivel}
                haveChildren={hasChildren(currentSubitem)}
                className={`tour-${clearTourClassName(currentSubitem._id)}`}
              >
                <ContentItem>
                  <p
                    title={currentSubitem.text}
                    onClick={() => redirecionarLink(currentSubitem)}
                    style={{
                      cursor:
                        [1, 2, 3].includes(item.nivel) &&
                        hasChildren(currentSubitem) &&
                        currentSubitem?.routeName === '/'
                          ? 'default'
                          : 'pointer'
                    }}
                    className={
                      currentSubitem?.routeName !== '/' ? 'clickable' : ''
                    }
                  >
                    {currentSubitem.text.toUpperCase()}

                    {item.nivel === 3 && hasChildren(currentSubitem) && (
                      <div
                        onClick={() => setSubMenuOpen((prev) => !prev)}
                        style={{ cursor: 'pointer', padding: '0 3px 0 3px' }}
                      >
                        {subMenuOpen ? (
                          <Icon type="up" />
                        ) : (
                          <Icon type="down" />
                        )}
                      </div>
                    )}
                  </p>
                </ContentItem>
                <div>
                  {hasChildren(currentSubitem) &&
                    renderSubMenuItem(currentSubitem)}
                </div>
              </SubMenuWrapper>
            </div>
          ))}
        </>
      );
    }
  };

  return <> {renderSubMenuItem(selectedItem)} </>;
};
