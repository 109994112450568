import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Dropdown, Icon, Menu, message } from 'antd';
import { formatDate, isStringNumber } from '@digi-tim-19/utils/build';
import {
  Breadcrumb,
  TitleDescription,
  Select,
  Button as ButtonComponent,
  useCurrentUser
} from '@digi-tim-19/components';

import { accessMaterial } from '../../../utils/material';
import { useDocumentCategories } from '../../../hooks/categories/useDocumentCategory';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { CleanButton, FilterContainer } from '../../../components/PageFilter';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumMaterialKind,
  ValidityEnum,
  EnumMaterialStatus,
  SortFindManyMaterialInput
} from '../../../autogenerated/client/types';
import { useMaterialOwners } from 'hooks/materials/useMaterialOwners';
import useDebounce from 'hooks/useDebounce';

import { ListarFragment } from '../fragments';
import { DocumentsListWrapper, Button, TreeSelect, Search } from './styles';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { ModalReportMenuView } from 'components/ReportMenu/ModalReportMenuView';
import { ModalReportDocumentsActions } from 'components/ReportDocuments/ModalReportDocumentsActions';
import { userIsTrade } from 'utils/validations';

const configPage = {
  pageName: 'Documentos',
  icon: 'inbox',
  description: 'Consulta e cadastramento de documentos',
  messageDeletedSuccess: 'Documento cancelado com sucesso',
  kind: EnumMaterialKind.Document,
  textButtonNew: 'Cadastrar documento',
  routeNew: routes.documentosCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Documentos', link: routes.documentosGerenciar.mount() }
  ],
  perPage: 10
};

export const DocumentosListar = () => {
  const history = useHistory();
  const user = useCurrentUser()?.result;
  const categoryOptions = useDocumentCategories();
  const ownerOptions = useMaterialOwners(configPage.kind);
  const getMaterials = useClient('MaterialPagination');
  const MaterialUpdateOne = useClient('MaterialUpdateOne');

  const [resultMaterial, setResultMaterial] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [updateTable, setUpdateTable] = useState('');
  const [filterValidityEnum, setFilterValidityEnum] = useState(
    ValidityEnum.Any
  );
  const [filterStatus, setFilterStatus] = useState<EnumMaterialStatus>();
  const [filterCategory, setFilterCategory] = useState<any>();
  const [filterUser, setFilterUser] = useState<string>();
  const [filterSearch, setFilterSearch] = useState<string>();
  const [isTitleSearch, setIsTitleSearch] = useState<boolean>(false);
  const [modal, setModal] = useState<string | undefined>(undefined);

  const debouncedSearchTerm = useDebounce(filterSearch, 500);

  const isTrade = userIsTrade(user);

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment: ListarFragment,
        variables: {
          page: page,
          perPage: configPage.perPage,
          filter: {
            kind: configPage.kind,
            inCategories: filterCategory,
            status: filterStatus,
            includeDrafts: true,
            validityEnum: filterValidityEnum,
            owner: filterUser,
            numericId:
              !isTitleSearch && debouncedSearchTerm
                ? +debouncedSearchTerm
                : undefined,
            search: (isTitleSearch && debouncedSearchTerm) || undefined
          },
          sort: filterSearch
            ? undefined
            : SortFindManyMaterialInput.UpdatedatDesc
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultMaterial(ctx.result.items);
        }
      });
  }, [
    page,
    filterValidityEnum,
    filterStatus,
    filterCategory,
    debouncedSearchTerm,
    filterUser,
    updateTable
  ]); //eslint-disable-line
  const total = getMaterials?.result?.pageInfo.itemCount;

  const resetFilters = () => {
    setFilterValidityEnum(ValidityEnum.Any);
    setPage(1);
    setFilterCategory(undefined);
    setFilterStatus(undefined);
    setFilterSearch(undefined);
    setFilterUser(undefined);
  };

  const data = resultMaterial.map((material: any) => {
    const validity = !material?.validity?.end
      ? 'Sem expiração'
      : `${formatDate(material?.validity?.start, 'DD/MM/YY')}
      até ${formatDate(material?.validity?.end, 'DD/MM/YY')}`;
    const category = material?.categories.find((item: any) => item)?.name;
    const status =
      material?.status?.label === 'Aprovado'
        ? 'Aguardando publicação'
        : material?.status?.label;

    return {
      key: material?._id,
      id: material?.numericId,
      title: material?.title,
      updatedAt: formatDate(material?.updatedAt, 'DD/MM/YY'),
      validity,
      category,
      status,
      author: material?.author?.name,
      onRead: () => accessMaterial(material, history, routes),
      onEdit: () => history.push(routes.documentosEditar.mount(material?._id)),
      onDelete: () => {
        const record = { status: EnumMaterialStatus.Cancelled };

        MaterialUpdateOne.fetch({
          variables: { record, filter: { _id: material._id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          message.success(configPage.messageDeletedSuccess);
          setUpdateTable(ctx.result?.recordId);
        });
      }
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      if (e.length === 6 && isStringNumber(e)) {
        return setIsTitleSearch(false);
      }
      return setIsTitleSearch(true);
    },
    [data]
  );

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <DocumentsListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Button to={routes.documentosCadastrar.mount()}>
            {configPage.textButtonNew}
          </Button>
          {isTrade && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => setModal('views')}>
                    <Icon type="file-excel" /> Visualização
                  </Menu.Item>
                  <Menu.Item onClick={() => setModal('actions')}>
                    <Icon type="file-excel" /> Criação/Edição
                  </Menu.Item>
                </Menu>
              }
              placement="bottomCenter"
            >
              <ButtonComponent>
                <Icon type="file-excel" /> Relatórios
              </ButtonComponent>
            </Dropdown>
          )}
          {Boolean(modal === 'views') && (
            <ModalReportMenuView
              treeSelect={true}
              kind={EnumMaterialKind.Document}
              options={categoryOptions}
              hideOpenCloseButton={true}
              onClose={() => setModal(undefined)}
            />
          )}
          {Boolean(modal === 'actions') && (
            <ModalReportDocumentsActions
              options={categoryOptions}
              onClose={() => setModal(undefined)}
            />
          )}
          <Select
            placeholder="Status"
            options={statusOptions}
            value={
              filterValidityEnum !== ValidityEnum.Any
                ? filterValidityEnum
                : filterStatus
            }
            onChange={(e) => {
              setPage(1);
              switch (e) {
                case ValidityEnum.Current:
                case ValidityEnum.Future:
                  setFilterValidityEnum(e);
                  setFilterStatus(EnumMaterialStatus.Approved);
                  break;
                case ValidityEnum.Expired:
                  setFilterValidityEnum(e);
                  setFilterStatus(undefined);
                  break;
                default:
                  setFilterValidityEnum(ValidityEnum.Any);
                  setFilterStatus(e);
              }
            }}
          />
          <TreeSelect
            disabled={!categoryOptions.length}
            treeDataSimpleMode
            treeData={categoryOptions}
            placeholder="CAMINHO"
            value={filterCategory}
            onChange={(e) => {
              setPage(1);
              setFilterCategory(e);
            }}
          />
          <Select
            placeholder="Buscar por solicitante"
            value={filterUser}
            onChange={(e) => setFilterUser(e)}
            options={ownerOptions}
          />
          <Search
            placeholder="Buscar por título ou ID"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              setPage(1);
              onSearch(e.target.value);
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        <TablePagination
          loading={getMaterials.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: configPage.perPage,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
        />
      </DocumentsListWrapper>
    </PageTemplate>
  );
};

const statusOptions = [
  { label: 'AGUARDANDO PUBLICAÇÃO', value: ValidityEnum.Future },
  { label: 'PUBLICADO', value: ValidityEnum.Current },
  { label: 'EXPIRADO', value: ValidityEnum.Expired },
  { label: 'CANCELADO', value: EnumMaterialStatus.Cancelled },
  { label: 'EM EDIÇÃO', value: EnumMaterialStatus.Editing }
  // { label: 'AGUARDANDO APROVAÇÃO', value: EnumMaterialStatus.WaitingApproval },
  // { label: 'REPROVADO', value: EnumMaterialStatus.Disapproved },
];
