import React from 'react';
import { Former, LoadingIndicator, useClient } from '@digi-tim-19/components';
import { RegionalProfilesField } from 'components/RegionalProfilesSelection/RegionalProfilesField';
import { Container } from './styles';
import { QuestionsField } from './QuestionsField';
import { useHistory } from 'react-router';
import { routes } from 'config/routes';
import { message } from 'antd';
import { RamificationField } from './RamificationField';
import { Research } from 'autogenerated/client/types';

interface Props {
  initialValues?: Partial<Research>;
}

export enum ResearchPage {
  research = 'research',
  home = 'home'
}

const options = [
  {
    label: 'Pesquisas (Padrão)',
    value: ResearchPage.research
  },

  {
    label: 'Home',
    value: ResearchPage.home
  }
];

export const GerenciarFormResearch = (props: Props) => {
  const history = useHistory();
  const [values, setValues] = React.useState(props?.initialValues || {});
  const [isRamification, setIsRamification] = React.useState(false);
  const createResearch = useClient('ResearchCreateOne');
  const updateResearch = useClient('ResearchUpdateOne');
  const isUpdate = !!props.initialValues;

  const initialValues = React.useMemo(() => {
    if (values?.ramification) {
      setIsRamification(true);
    }
    return {
      ...values,
      page: values?.page || 'research',
      isRequired: false
    };
  }, [values]);

  if (createResearch.loading || updateResearch.loading)
    return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={(renderProps) => {
          const { form } = renderProps;
          form.registerField('questions', () => {}, {});
          form.registerField('ramification', () => {}, {});
          form.registerField('page', () => {}, {});
          const showHomePage =
            form.getFieldState('page')?.value === 'home' ? true : false;

          return {
            fields: [
              {
                inline: true,
                list: [
                  {
                    name: 'title',
                    label: 'Título da pesquisa',
                    required: true,
                    validate: (value: any) => {
                      const format = /[#%&;]/;
                      if (format.test(value))
                        return 'Os caracteres #%&; não são permitidos';
                      if (value)
                        return !(value.length > 70)
                          ? undefined
                          : 'Máximo de 70 caracteres';
                      return undefined;
                    }
                  }
                ]
              },
              {
                inline: true,
                list: [
                  {
                    name: 'page',
                    label: 'Página de exibição da pesquisa',
                    type: 'select',
                    required: true,
                    options
                  },
                  showHomePage && {
                    name: 'isRequired',
                    label: 'Pesquisa obrigatória',
                    type: 'select',
                    options: [
                      {
                        label: 'Não',
                        value: false
                      },
                      {
                        label: 'Sim',
                        value: true
                      }
                    ]
                  }
                ]
              },
              {
                inline: true,
                list: [
                  {
                    name: 'validity',
                    label: 'Data de Vigência',
                    type: 'rangePicker',
                    required: false,
                    maxWidth: '100%',
                    extraProps: {
                      format: 'DD/MM/YYYY HH:mm',
                      showTime: { format: 'HH:mm' }
                    }
                  },
                  {
                    name: 'availableAtRegionalProfiles',
                    className: 'selecionar-profile',
                    custom: (
                      <RegionalProfilesField
                        fieldConfig={{
                          initialValue:
                            initialValues?.availableAtRegionalProfiles
                        }}
                        defaultChecked={
                          isUpdate
                            ? undefined
                            : [
                                'THQ#residencial:adm_trade_residencial',
                                'THQ#corporate:adm_trade_corporate'
                              ]
                        }
                      />
                    )
                  }
                ]
              },
              {
                inline: true,
                list: [
                  {
                    inline: true,
                    custom: (
                      <QuestionsField
                        isRamification={
                          form.getFieldState('ramification')?.value
                        }
                        disabled={isUpdate}
                        values={form.getFieldState('questions')?.value}
                        onChange={(data) => {
                          form.change('questions', data);
                        }}
                      />
                    )
                  }
                ]
              },
              {
                inline: true,
                list: [
                  {
                    inline: true,
                    custom: (
                      <RamificationField
                        disabled={isUpdate}
                        questions={form.getFieldState('questions')?.value}
                        value={isRamification}
                        onChange={(data) => {
                          form.change('questions', data);
                        }}
                        onChangeRamificationField={(value) => {
                          setIsRamification(value);
                          form.change('ramification', value);
                          if (!value) {
                            const data = (
                              form.getFieldState('questions')?.value || []
                            ).map((el: any) => {
                              if (
                                el?.type &&
                                !['text', 'range'].includes(el.type) &&
                                el?.alternatives
                              ) {
                                return {
                                  ...el,
                                  alternatives: el.alternatives.map(
                                    (item: any) => {
                                      return {
                                        id: item.id,
                                        description: item.description
                                      };
                                    }
                                  )
                                };
                              }
                              return el;
                            });
                            form.change('questions', data);
                          }
                        }}
                      />
                    )
                  }
                ]
              }
            ],
            submitButton: {
              label: isUpdate ? 'Atualizar' : 'Cadastrar'
            },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.researchGerenciar.path)
            }
          };
        }}
        onSubmit={async ({ data }) => {
          if (!data?.questions || data?.questions?.length === 0) {
            setValues({ ...data });
            return message.error(
              'Por favor, adicione questões para a pesquisa.'
            );
          }

          if (data?.ramification) {
            const invalid = Boolean(
              data.questions.find((question: any) => {
                if (question?.alternatives) {
                  return Boolean(
                    question.alternatives.find(
                      (el: any) => !el?.ramificationQuestionId
                    )
                  );
                }
                return false;
              })
            );
            if (invalid) {
              setValues({ ...data, ramification: isRamification });
              return message.error(
                'Por favor, preencha todos os campos da ramificação das questões.'
              );
            }
          } else {
            data.questions = data.questions.map((question: any) => {
              if (question?.alternatives) {
                question.alternatives = question.alternatives.map((el: any) => {
                  delete el.ramificationQuestionId;
                  return { ...el };
                });
              }
              return { ...question };
            });
          }

          let record: any = {
            title: data.title,
            page: data?.page || 'research',
            isRequired: data?.isRequired,
            questions: data.questions,
            ramification: isRamification,
            availableAtRegionalProfiles: data?.availableAtRegionalProfiles || []
          };

          if (data?.validity && Object.keys(data?.validity).length > 0) {
            record.validity = {
              start: data?.validity[0] || data?.validity?.start,
              end: data?.validity[1] || data?.validity?.end
            };
          }

          const callback = (ctx: { result?: any }) => {
            if (ctx?.result) {
              message.success(
                `Pesquisa ${
                  isUpdate ? 'atualizada' : 'cadastrada'
                } com sucesso.`
              );
              history.push(routes.researchGerenciar.mount());
            } else {
              setValues({ ...record });
              message.error(
                `Pesquisa não ${isUpdate ? 'atualizada' : 'criada.'}`
              );
            }
          };

          if (isUpdate) {
            await updateResearch
              .fetch({
                variables: { filter: { _id: props.initialValues!._id }, record }
              })
              .then(callback);
          } else {
            await createResearch
              .fetch({ variables: { record } })
              .then(callback);
          }
        }}
      />
    </Container>
  );
};
