import { useHistory } from 'react-router';
import { routes } from 'config/routes';

export function useMenuNavigation() {
  const history = useHistory();

  function redirecionarLink(
    itemClick: any,
    subMenuClose?: any,
    menuClose?: any,
    isMobile?: boolean
  ) {
    if (
      itemClick?.routeName === '/' &&
      itemClick?.children &&
      itemClick?.children?.length > 0
    ) {
      return;
    }

    if (itemClick?.routeName !== '/') {
      const idSplit = itemClick!._id!.split(':').splice(1);
      const key: string = itemClick.routeName;
      const rout = (routes as any)[key];

      const newRout = rout.mount(...idSplit);

      newRout && history.push(newRout);
    }

    if (isMobile) {
      return menuClose();
    }

    if (subMenuClose) {
      return subMenuClose();
    }
  }

  return { redirecionarLink };
}
