import React from 'react';
import { Select, useClient } from '@digi-tim-19/components';
import { Modal, message, TreeSelect as AntdTreeSelect } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { downloadXLSX } from '../../utils/downloadXlsx';

interface Props {
  options: any[];
  hideOpenCloseButton?: boolean;
  onClose?: Function;
}

const fragment = `
  numericId
  title
  menu
  createdAt
  createdByUserName
  createdByUserMatricula
  createdByUserRoleGroup
  updatedAt
  updatedByUserName
  updatedByUserMatricula
  updatedByUserRoleGroup
`;

export const ModalReportDocumentsActions = ({
  options = [],
  onClose
}: Props) => {
  const [category, setCategory] = React.useState<any>();
  const [dateView, setDateView] = React.useState<any>();
  const { loading, fetch } = useClient('ReportDocumentsActions', { fragment });

  const handleGenerateYears = () => {
    let options: any = [];

    for (let year = 2024; year <= moment().year(); year++) {
      options.push({
        label: `Ano/${year}`,
        value: `${year}-01-01`
      });
    }

    return options;
  };

  const handleSerializeResult = (result: any[]) => {
    return result.map((el: any) => {
      return {
        numericId: el?.numericId || '',
        title: el?.title || '',
        menu: el?.menu || '',
        createdAt: el?.createdAt || '',
        createdByUserName: el?.createdByUserName || '',
        createdByUserRoleGroup: el?.createdByUserRoleGroup || '',
        createdByUserMatricula: el?.createdByUserMatricula || '',
        updatedAt: el?.updatedAt || '',
        updatedByUserName: el?.updatedByUserName || '',
        updatedByUserRoleGroup: el?.updatedByUserRoleGroup || '',
        updatedByUserMatricula: el?.updatedByUserMatricula || ''
      };
    });
  };

  const handleDownloadReport = async (category: string, dateView: string) => {
    const { result } = await fetch({
      variables: { date: dateView, menu: category }
    });
    if (result && result?.length > 0) {
      const schema: any = {
        numericId: { label: 'ID do documento' },
        title: { label: 'Título do documento' },
        menu: { label: 'Menu do documento' },
        createdAt: { label: 'Data (Criação)' },
        createdByUserName: { label: 'Nome (Criação)' },
        createdByUserRoleGroup: { label: 'Perfil (Criação)' },
        createdByUserMatricula: { label: 'Matrícula (Criação)' },
        updatedAt: { label: 'Data (Edição)' },
        updatedByUserName: { label: 'Nome (Edição)' },
        updatedByUserRoleGroup: { label: 'Perfil (Edição)' },
        updatedByUserMatricula: { label: 'Matrícula (Edição)' }
      };

      downloadXLSX(
        `${moment().format(
          'DD-MM-YYYY-HH-mm'
        )}-relatório-criação-edição-documentos`,
        schema,
        handleSerializeResult(result)
      );
      handleCloseModal();
    } else {
      message.info('Não há registros para download da planilha.');
    }
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Modal
        title="Relatório de criação/edição"
        visible={true}
        okText="DOWNLOAD"
        cancelText="FECHAR"
        onOk={async () => await handleDownloadReport(category, dateView)}
        onCancel={() => {
          if (!loading) {
            handleCloseModal();
          }
        }}
        okButtonProps={{
          disabled: !category || !dateView,
          loading: loading
        }}
      >
        <Message>
          Este relatório pode demorar alguns minutos para finalizar o
          processamento. Por favor, permanecer na tela até a conclusão do
          download. Os dados serão ordenados pela data de atualização dos
          documentos.
        </Message>
        <TreeSelect
          disabled={!options.length}
          treeDataSimpleMode
          treeData={options.map((option: any) => {
            return { ...option, selectable: true };
          })}
          placeholder="MENU"
          value={category}
          onChange={(e) => {
            setCategory(e);
          }}
        />
        <Select
          style={{ marginTop: '10px' }}
          placeholder="ANO"
          options={handleGenerateYears()}
          value={dateView}
          onChange={(e) => {
            setDateView(e);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

const Message = styled.div`
  width: 100%;
  font-size: 11pt;
  font-style: italic;
  margin: -10px 0px 10px 0px;
`;

const TreeSelect = styled(AntdTreeSelect)`
  color: ${(props) => props.theme.blue};
  width: 100%;
  margin-right: 15px;

  .ant-select-selection__placeholder,
  .ant-select-selection__rendered {
    text-transform: uppercase;
  }

  i {
    color: ${(props) => props.theme.warning};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
    &::after {
      content: '';
      margin: 0px 5px;
      width: 0;
      height: 0;
      border: solid ${(props) => props.theme.blue};
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform-origin: center center;
      transition: all 0.3s;
      transform: rotate(45deg);
    }
  }

  &.ant-select-open {
    text-transform: uppercase;
    i {
      &::after {
        transform: rotate(225deg);
        margin: -2px 5px;
      }
    }
  }
`;
