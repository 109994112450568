import {
  EnumFileKind,
  EnumMaterialCommunicationItemsConfigKind,
  MaterialCommunicationItemsConfig,
  Remuneration
} from 'autogenerated/client/types';
import React from 'react';
import { FormWrapper, WrapperLabel } from './styles';
import {
  Former,
  LabelSelecionarPublico,
  SearchUser,
  usePublico
} from '@digi-tim-19/components';
import { validateSelectPublic } from 'utils/validateSelectPublic';
import { useDimensions } from '@digi-tim-19/utils';
import { useHistory } from 'react-router';
import { routes } from 'config/routes';
import { message } from 'antd';
import { RegionalProfilesField } from 'components/RegionalProfilesSelection/RegionalProfilesField';
import { Divider } from 'components/Former';
import { useClient } from 'autogenerated/client/client';
import { TemplateFields } from 'pages/Informativos/FormInformativos/TemplateFields';
import { siteImages } from 'assets/images';
import { EmailProps } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';
import { notifyEmptyFields } from 'pages/Informativos/FormInformativos/FormInformativos';
import { isObjectId } from 'utils/validations';
import { UploadMailing } from 'components/UploadMailing';

interface Props {
  values?: Partial<Remuneration>;
}

const configPage = {
  kindThumb: EnumFileKind.CardThumb,
  kindFile: EnumFileKind.Pdf,
  routeList: routes.comissionamentoListar.mount(),
  messageSuccess: 'Documento sobre remuneração salvo com sucesso.',
  messageError:
    'Falha ao salvar o documento sobre remuneração, verifique os dados preenchidos e tente novamente.'
};

export const REMUNERACOES_CATEGORY =
  'menu:mobile_e_ict:remuneracao_variavel_e_metas';

export const FormRemuneracoes = ({ values }: Props) => {
  const isUpdate = !!values;
  const [data, setData] = React.useState<any>(values);
  const [publicSelected, setPublicSelected] = React.useState(false);
  const { availableAtChannels, availableAtRegions } = usePublico();
  const history = useHistory();
  const { isMobile } = useDimensions();
  const remunerationCreateOne = useClient('RemunerationCreateOne');
  const remunerationUpdateOne = useClient('RemunerationUpdateOne');

  const initialValues: any = React.useMemo(() => {
    const validityStart: any = data?.remunerationValidity?.start;
    const validityEnd: any = data?.remunerationValidity?.end;
    const email = data?.communicationItemsConfig?.length
      ? data?.communicationItemsConfig[0]
      : undefined;
    const IMAGE_SRC_2 =
      email?.templateProps?.IMAGE_SRC_2 || siteImages.bgTemplateEmail640x300;
    let IMAGE_SRC_1: any = null;

    if (isUpdate) {
      if (
        email?.templateName &&
        [
          'templateCorp11',
          'templateCorp12',
          'templateCorp13',
          'templateCorp14',
          'templateCorp15'
        ].includes(email?.templateName)
      ) {
        IMAGE_SRC_1 =
          email?.templateProps?.IMAGE_SRC_1 ||
          siteImages.bgTemplateEmail600x900;
      } else {
        IMAGE_SRC_1 = email?.templateProps?.IMAGE_SRC_1;
      }
    }

    const templateProps = {
      ...email?.templateProps,
      IMAGE_SRC_1,
      IMAGE_SRC_2
    };

    return {
      ...data,
      templateName: email?.templateName,
      templateProps,
      mailing:
        data?.mailing && isObjectId(data?.mailing)
          ? data.mailing
          : data?.mailingFile?._id || '',
      availableAtRoleGroups: data?.availableAtRoleGroups || [],
      availableAtChannels: data?.availableAtChannels || [],
      validity: [validityStart, validityEnd],
      schedule: data?.validity?.start
    };
  }, [data]);

  return (
    <FormWrapper>
      <Former
        initialValues={initialValues}
        config={({ form }) => {
          form.registerField('mailing', () => {}, {});
          return {
            fields: [
              {
                inline: !isMobile,
                list: [
                  {
                    name: 'schedule',
                    label: 'PROGRAMAR PUBLICAÇÃO',
                    type: 'datePicker',
                    required: true,
                    enableDatePriorToCurrent: true,
                    extraProps: {
                      format: 'DD/MM/YYYY',
                      setStartDay: true
                    }
                  },
                  {
                    name: 'validity',
                    label: 'Data de Vigência',
                    type: 'rangePicker',
                    required: true,
                    extraProps: {
                      startPlaceholder: 'Início da vigência',
                      endPlaceholder: 'Fim da vigência'
                    }
                  },
                  {
                    name: 'videoLink',
                    label: 'LINK PARA O VÍDEO',
                    type: 'text'
                  }
                ]
              },
              {
                inline: true,
                name: 'title',
                label: 'TÍTULO (até 110 caracteres)',
                required: true,
                validate: (value: any) => {
                  const format = /[#%&;]/;
                  if (format.test(value))
                    return 'Os caracteres #%&; não são permitidos';

                  if (value) {
                    return !(value.length > 110)
                      ? undefined
                      : 'Máximo de 110 caracteres';
                  }

                  return undefined;
                }
              },
              {
                inline: !isMobile,
                list: [
                  {
                    name: 'bannerContent',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: EnumFileKind.AnyImage,
                      CTA: 'CONTEÚDO DO BANNER (970x340)'
                    }
                  },
                  {
                    name: 'file',
                    label: '',
                    type: 'upload',
                    required: true,
                    extraProps: {
                      kind: configPage.kindFile,
                      CTA: 'IMPORTAR ARQUIVO',
                      allowedExtensions: ['application/pdf'],
                      multiple: false
                    }
                  }
                ]
              },
              {
                custom: <Divider />
              },
              {
                name: 'faq',
                label: 'CADASTRAR FAQ',
                type: 'texteditor',
                validate: (value: any) => {
                  const format = /[#%&]/;
                  if (format.test(value))
                    return 'Os caracteres #%& não são permitidos';

                  if (value) {
                    return !(value.length > 1200)
                      ? undefined
                      : 'Máximo de 1200 caracteres';
                  }

                  return undefined;
                }
              },
              {
                custom: <Divider />
              },
              {
                custom: {
                  render: () => <TemplateFields section="remuneration" />
                }
              },
              {
                custom: <Divider />
              },
              {
                className: 'Footer',
                inline: !isMobile,
                list: [
                  {
                    custom: (
                      <RegionalProfilesField
                        readonly={isUpdate}
                        fieldConfig={{
                          initialValue: data?.availableAtRegionalProfiles
                        }}
                        defaultChecked={
                          isUpdate
                            ? undefined
                            : [
                                'THQ#residencial:adm_trade_residencial',
                                'THQ#corporate:adm_trade_corporate'
                              ]
                        }
                      />
                    )
                  },
                  publicSelected && {
                    custom: (
                      <WrapperLabel>
                        <LabelSelecionarPublico />
                      </WrapperLabel>
                    )
                  },
                  {
                    custom: (
                      <UploadMailing
                        file={data?.mailingFile}
                        onChange={(_id) => form.change('mailing', _id)}
                      />
                    )
                  },
                  {
                    name: 'availableForUsers',
                    label: 'Responsável',
                    placeholder: 'BUSCAR NOME OU E-MAIL',
                    component: SearchUser
                  }
                ]
              }
            ],
            submitButton: {
              label: isUpdate ? 'Atualizar' : 'Salvar',
              onClick: (formerRef: any) => {
                setPublicSelected(validateSelectPublic(formerRef));
              }
            },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(configPage.routeList)
            }
          };
        }}
        onSubmit={async ({ data: formData }: any) => {
          const startValidity = formData.validity[0] || formData.validity.start;
          const endValidity = formData.validity[1] || formData.validity.end;
          const templateProps: EmailProps = formData.templateProps;
          let emailConfig: MaterialCommunicationItemsConfig;

          if (formData.templateName !== 'templateCorp11') {
            const validation = [
              { name: 'Link do botão', value: templateProps.BUTTON_LINK },
              { name: 'Texto do botão', value: templateProps.BUTTON_TITLE },
              { name: 'Descrição do template', value: templateProps.TEXT_1 }
            ];

            if (formData.templateName !== 'templateCorp12') {
              validation.push({
                name: 'Imagem',
                value: templateProps.IMAGE_SRC_1
              });
            }

            const hasValidTemplateConfig = notifyEmptyFields(validation);

            if (hasValidTemplateConfig) {
              const { html, ...cleared } = formData.templateProps;
              emailConfig = {
                kind: EnumMaterialCommunicationItemsConfigKind.Email,
                templateName: formData.templateName,
                templateProps: cleared
              };
            } else {
              return;
            }
          } else {
            const hasValidTemplateConfig = notifyEmptyFields([
              { name: 'Imagem 1', value: templateProps.IMAGE_SRC_1 },
              { name: 'Título do template', value: formData.title }
            ]);

            if (hasValidTemplateConfig) {
              const { html, ...cleared } = formData.templateProps;

              const hideButton =
                cleared.HIDE_BUTTON === undefined ||
                cleared.HIDE_BUTTON === null
                  ? true
                  : cleared.HIDE_BUTTON;

              emailConfig = {
                kind: EnumMaterialCommunicationItemsConfigKind.Email,
                templateName: formData.templateName,
                templateProps: {
                  ...cleared,
                  TEXT_1: ' ',
                  TITLE_1: formData.title,
                  BUTTON_LINK: cleared.BUTTON_LINK || 'SEM LINK',
                  BUTTON_TITLE: cleared.BUTTON_TITLE || 'SEM BOTÃO',
                  HIDE_BUTTON: hideButton
                }
              };
            } else {
              return;
            }
          }

          const record: any = {
            availableAtRegions,
            availableAtChannels,
            availableAtRegionalProfiles: formData.availableAtRegionalProfiles,
            availableForUsers: formData?.availableForUsers || [],
            title: formData.title,
            category: 'remuneration',
            videoLink: formData.videoLink,
            enableSocialInteraction: false,
            regulation: formData.regulation,
            faq: formData.faq,
            cardImage: formData?.cardImage || undefined,
            bannerContent: formData?.bannerContent || undefined,
            file: formData?.file || undefined,
            presentation: formData?.presentation || undefined,
            validity: { start: formData.schedule },
            remunerationValidity: { start: startValidity, end: endValidity },
            mailing:
              Boolean(formData?.mailing) && isObjectId(formData?.mailing)
                ? formData?.mailing
                : undefined
          };

          if (emailConfig) {
            record.communicationItemsConfig = [emailConfig];
          }

          if (!formData?.file) {
            setData({ ...record });
            return message.error('Selecione um arquivo');
          }

          if (!formData?.availableAtRegionalProfiles) {
            setData({ ...record });
            return setPublicSelected(true);
          }

          if (isUpdate && data?._id) {
            await remunerationUpdateOne
              .fetch({
                variables: { record, filter: { _id: data._id } }
              })
              .then((ctx) => {
                if (!ctx.errors) {
                  message.success(configPage.messageSuccess);
                  history.push(configPage.routeList);
                } else {
                  setData({ ...record });
                  message.error(configPage.messageError);
                }
              });
          } else {
            await remunerationCreateOne
              .fetch({
                variables: {
                  record: { ...record }
                }
              })
              .then((ctx) => {
                if (ctx?.result) {
                  message.success(configPage.messageSuccess);
                  history.push(configPage.routeList);
                } else {
                  setData({ ...record });
                  message.error(configPage.messageError);
                }
              });
          }
        }}
      />
    </FormWrapper>
  );
};
