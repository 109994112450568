import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { PageWrapper } from './styles';
import { FormAnswer } from './FormAnswer';
import { useHistory, useRouteMatch } from 'react-router';
import { useClient } from 'autogenerated/client/client';
import styled from 'styled-components';

const configPage = {
  pageName: 'Responder pesquisa',
  icon: 'schedule',
  description: 'Responder e/ou consultar pesquisa',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Pesquisas', link: routes.researchListar.mount() },
    { title: 'Responder' }
  ]
};

const appendToFragment = `
  _id
  title
  questions
  ramification
  validity {
    start
    end
  }
  answers {
    userId
    researchId
    answers
  }
  createdAt
  updatedAt
`;

const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 20px 15px;
  margin-bottom: 25px;
`;

export const ResearchAnswer = () => {
  const { params }: any = useRouteMatch();
  const history = useHistory();
  const getResearch = useClient('ResearchFindById', {
    appendToFragment,
    variables: { _id: params?.id! },
    fetchOnMount: true
  });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <React.Fragment>
        <PageWrapper>
          <TitleDescription
            iconType={'schedule'}
            title={configPage.pageName}
            description={configPage.description}
          />
        </PageWrapper>
        <Container>
          <ProviderSelecionarPublico>
            <FormAnswer
              initialValues={getResearch?.result}
              loadingValues={getResearch?.loading}
              onFinish={() => {
                history.push(routes.researchListar.mount());
              }}
            />
          </ProviderSelecionarPublico>
        </Container>
      </React.Fragment>
    </PageTemplate>
  );
};
