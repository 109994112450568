import React from 'react';
import styled from 'styled-components';
import { TypeMenuItem } from '../../PageTemplate';
import { MainMenuConfig } from '../MenuPrincipal';
import { UserSession } from '@digi-tim-19/utils';
import { useCurrentUser } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { SubMenuMobile } from './SubMenuMobile';
import { Icon } from 'antd';
import { User } from '@digi-tim-19/components/build/main/DataProviders/types';
import { colors } from '@digi-tim-19/theme';

const NavWrapper = styled.nav<{ menuOpen?: boolean }>`
  display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
  height: max-content;
  min-width: 100%;
  padding: 20px;
  position: relative;
`;

const ListMenu = styled.ul`
  padding-left: 0px;
  height: 100%;
  list-style: none;
  padding: 8px;
`;

const ItemMenu = styled.li`
  color: ${colors.blue};
  width: 100%;
  font-weight: 700;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: TimBold;
  padding: 8px 16px;
`;

export const MenuPrincipalMobile = ({
  menuIsOpen,
  itemsMenuSettings,
  onMenuExpand,
  redirecionarLink
}: MainMenuConfig) => {
  const user = useCurrentUser().result as User;
  const history = useHistory();

  function handleItemMenuClick(itemMenu: TypeMenuItem) {
    const routeName = itemMenu?.routeName;
    const children = itemMenu?.children || [];

    if (routeName !== '/' && children.length < 1) {
      onMenuExpand();
      redirecionarLink(itemMenu);
      return;
    }

    if (routeName !== '/') {
      return onMenuExpand();
    }
  }

  function handleLogout() {
    UserSession.clear();
    const redirectUrl = user?.matricula ? '/open/login' : '/';
    document.location.href = redirectUrl;
  }

  return (
    <>
      <NavWrapper menuOpen={menuIsOpen}>
        <ListMenu>
          {itemsMenuSettings.items && (
            <ItemMenu
              onClick={() => {
                history.push('/');
                onMenuExpand();
              }}
            >
              <Icon
                style={{
                  fontSize: '24px',
                  paddingRight: '8px'
                }}
                type={'home'}
              />
              <span>Home</span>
            </ItemMenu>
          )}

          {itemsMenuSettings.items &&
            itemsMenuSettings.items.map((itemMenu: TypeMenuItem) => (
              <SubMenuMobile
                subMenuitem={itemMenu}
                handleItemMenuClick={() => handleItemMenuClick(itemMenu)}
                redirecionarLink={redirecionarLink}
                key={itemMenu._id}
              />
            ))}

          {itemsMenuSettings.items && (
            <ItemMenu onClick={() => handleLogout()}>
              <Icon
                style={{ fontSize: '24px', paddingRight: '8px' }}
                type={'logout'}
              />
              <span>Sair</span>
            </ItemMenu>
          )}
        </ListMenu>
      </NavWrapper>
    </>
  );
};
