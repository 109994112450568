import React from 'react';
import {
  Maybe,
  Research,
  ResearchByPagePayload
} from 'autogenerated/client/types';
import { Modal as AntdModal, Skeleton } from 'antd';
import styled from 'styled-components';
import { FormAnswer } from './FormAnswer';

interface Props {
  values?: Maybe<Research | ResearchByPagePayload>;
  loading?: boolean;
  open?: boolean;
  onClose: Function;
  onFinish: Function;
}

const Modal = styled(AntdModal)`
  .research-required {
    display: none;
  }

  .ant-modal-header {
    padding: 16px !important;
    .ant-skeleton {
      width: 93%;
    }
    .ant-skeleton-title {
      height: 18px !important;
      border-radius: 4px !important;
      margin: 5px 0;
    }
  }
  .ant-modal-body {
    padding: 12px 16px;
    max-height: 500px;
    overflow: auto;
    .research-title {
      display: none !important;
    }
  }
`;

export const AnswerResearchModal = ({
  values,
  loading = false,
  open = false,
  onClose,
  onFinish
}: Props) => {
  return (
    <Modal
      style={{ top: 20 }}
      className={Boolean(values?.isRequired) ? 'research-required' : ''}
      closable={!Boolean(values?.isRequired)}
      title={
        loading ? (
          <Skeleton active title paragraph={false} />
        ) : (
          values?.title || 'Pesquisa'
        )
      }
      visible={open}
      onCancel={() => !Boolean(values?.isRequired) && onClose()}
      okButtonProps={{
        hidden: true
      }}
      cancelButtonProps={{
        hidden: true
      }}
      footer={null}
    >
      <FormAnswer
        initialValues={values as Research}
        loadingValues={loading}
        onFinish={onFinish}
      />
    </Modal>
  );
};
