import React from 'react';
import {
  Button,
  LoadingIndicator,
  useClient,
  useCurrentUser,
  useUpload
} from '@digi-tim-19/components';
import { Container } from './styles';
import {
  Input,
  message,
  Radio as AntdRadio,
  Icon,
  Divider,
  Button as AntdButton,
  Upload
} from 'antd';
import styled from 'styled-components';
import { EnumFileKind, Maybe, Research } from 'autogenerated/client/types';

const { TextArea } = Input;

interface Props {
  initialValues?: Maybe<Research>;
  loadingValues?: boolean;
  onFinish: Function;
}

export interface QuestionInterface {
  id: string;
  description?: string;
  type?: string;
  upload?: boolean;
  ramification?: boolean;
  alternatives?: {
    id: string;
    ramificationQuestionId?: string;
    description: string;
  }[];
}

export interface AnswerInterface {
  questionId?: string;
  ramificationQuestionId?: string[];
  answer?: string | string[];
  fileId?: string;
  file?: any;
}

interface SelectRadioMultipleProps {
  questionId: string;
  disabled?: boolean;
  values?: string[];
  alternatives?: {
    id: string;
    ramificationQuestionId?: string;
    description: string;
  }[];
  onChange: (answer: AnswerInterface) => void;
}

interface FileUpload {
  questionId: string;
  file: any;
  fileId?: string;
}

const Title = styled.div`
  width: 100%;
  font-size: 20pt;
  margin-bottom: 20px;
`;

const Question = styled.p`
  margin: 0;
`;

const TextAreaInput = styled(TextArea)`
  margin: 10px 0;
`;

const RangeContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 20px;
`;

const Radio = styled(AntdRadio)`
  display: block;
  padding: 8px;
  white-space: break-spaces !important;
  flex: 1;
`;

const RadioGroupContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100% !important;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  &:hover {
    background-color: #ededed;
    border-radius: 10px;
  }
`;

const RangeOption = styled.div<{ $checked?: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #dddddd;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  ${(props) => (props?.$checked ? 'border: 1px solid gray;' : '')}
  &:hover {
    border: 1px solid gray;
  }

  > i {
    position: absolute;
    top: -7px;
    right: -9px;
  }

  > i svg {
    fill: gray !important;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const UploadContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ShowQuestionImage = ({
  files = [],
  questionId,
  value
}: {
  files: FileUpload[];
  questionId: string;
  value?: any;
}) => {
  const [image, setImage] = React.useState<any>();

  React.useEffect(() => {
    const image = files.find((file) => file.questionId === questionId)?.file;
    if (image) {
      setImage(image);
    }
  }, [files, value]);

  if (!image && !value?.signedUrl) return null;

  return (
    <img
      src={
        image?.file?.originFileObj
          ? URL.createObjectURL(image?.file?.originFileObj)
          : value?.signedUrl
      }
      alt="Imagem da pergunta"
      style={{ width: '200px', height: '200px' }}
    />
  );
};

const SelectRadioMultiple = ({
  questionId,
  alternatives,
  values = [],
  disabled = false,
  onChange
}: SelectRadioMultipleProps) => {
  const [checkeds, setCheckeds] = React.useState<string[]>([]);

  const handleCheckRadio = (questionId: string, description: string) => {
    setCheckeds((prevState) => {
      if (prevState.includes(description)) {
        const filtered = prevState.filter((el) => el !== description);
        const ramificationQuestionId: string[] =
          alternatives
            ?.filter(
              (el) =>
                el?.ramificationQuestionId && filtered.includes(el.description)
            )
            ?.map((el) => String(el.ramificationQuestionId)) || [];
        onChange({
          questionId,
          ramificationQuestionId:
            ramificationQuestionId.length > 0
              ? ramificationQuestionId
              : undefined,
          answer: filtered.length > 0 ? [...filtered] : undefined
        });
        return [...filtered];
      }
      const ramificationQuestionId: string[] =
        alternatives
          ?.filter(
            (el) =>
              el?.ramificationQuestionId &&
              (description === el.description ||
                prevState.includes(el.description))
          )
          ?.map((el) => String(el.ramificationQuestionId)) || [];
      onChange({
        questionId,
        ramificationQuestionId:
          ramificationQuestionId.length > 0
            ? ramificationQuestionId
            : undefined,
        answer: [...prevState, description]
      });
      return [...prevState, description];
    });
  };

  return (
    <RadioGroupContainer>
      {alternatives &&
        alternatives.map((alternative) => {
          return (
            <RadioContainer key={`alternative-${alternative.id}`}>
              <Radio
                disabled={disabled}
                checked={
                  checkeds.includes(alternative.description) ||
                  values.includes(alternative.description)
                }
                value={alternative.description}
                onClick={() =>
                  handleCheckRadio(questionId, alternative.description)
                }
              >
                {alternative.description}
              </Radio>
            </RadioContainer>
          );
        })}
    </RadioGroupContainer>
  );
};

export const FormAnswer = ({
  initialValues,
  loadingValues = false,
  onFinish
}: Props) => {
  const execute = useUpload(EnumFileKind.AnyImage);
  const [loading, setLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState<QuestionInterface[]>([]);
  const [answers, setAnswers] = React.useState<AnswerInterface[]>([]);
  const [files, setFiles] = React.useState<FileUpload[]>([]);
  const userId = useCurrentUser().result?._id;
  const createAnswerResearch = useClient('AnswerResearchCreateOne');
  const disabled =
    createAnswerResearch?.loading || Boolean(initialValues?.answers);
  const isRamification = Boolean(initialValues?.ramification);
  const questionsId = questions.map((el) => el.id);

  const handleChangeAnswer = (
    value: AnswerInterface,
    questionsId: string[]
  ) => {
    setAnswers((prevState: AnswerInterface[]) => {
      const filtered = prevState.filter(
        (el: AnswerInterface) =>
          el.questionId &&
          el.questionId !== value.questionId &&
          questionsId.includes(el.questionId)
      );
      if (value?.answer) {
        return [...filtered, { ...value }];
      }
      return [...filtered];
    });
  };

  const handleCheckAllAnswers = (
    questions: QuestionInterface[],
    answers: AnswerInterface[]
  ) => {
    return Boolean(
      questions.find((question) => {
        return !Boolean(
          answers.find((answer) => answer.questionId === question.id)
        );
      })
    );
  };

  const handleCheckUploads = (
    questions: QuestionInterface[],
    files: FileUpload[]
  ) => {
    return Boolean(
      questions.find((question) => {
        return (
          question?.upload &&
          !Boolean(files.find((file) => file.questionId === question.id))
        );
      })
    );
  };

  const handleMountQuestionsIfRamification = (
    isRamification: boolean,
    questions: QuestionInterface[],
    answers: AnswerInterface[]
  ) => {
    if (!isRamification) return setQuestions([...questions]);
    let newQuestions: QuestionInterface[] = [];

    for (let index = 0; index < questions.length; index++) {
      if (
        index === 0 ||
        (['text', 'range'].includes(questions[index]?.type!) &&
          ['text', 'range'].includes(questions[index - 1]?.type!)) ||
        (!['text', 'range'].includes(questions[index]?.type!) &&
          ['text', 'range'].includes(questions[index - 1]?.type!))
      ) {
        newQuestions.push(questions[index]);
        continue;
      }

      const nexts = questions.filter((el) => {
        return Boolean(
          answers.find((answer) =>
            answer?.ramificationQuestionId?.includes(el.id)
          )
        );
      });

      const hasNextRamification = questions[index]?.alternatives?.find(
        (el) =>
          el?.ramificationQuestionId && el?.ramificationQuestionId !== 'finish'
      );
      const hasInNexts = nexts.find((el) => el.id === questions[index]?.id);

      if (hasInNexts && !hasNextRamification) {
        newQuestions.push(questions[index]);
        break;
      }

      nexts.map((el) => {
        const exists = newQuestions.find((question) => question.id === el.id);
        if (!exists) newQuestions.push(el);
      });

      break;
    }

    setQuestions([...newQuestions]);
  };

  const handleChangeUpload = (file: any, questionId: string) => {
    setFiles((prev) => {
      const filtered = prev.filter((el) => el.questionId !== questionId);
      return [...filtered, { questionId, file }];
    });
  };

  React.useEffect(() => {
    handleMountQuestionsIfRamification(
      isRamification,
      initialValues?.questions || [],
      answers
    );
  }, [initialValues?.questions, answers]);

  React.useEffect(() => {
    if (initialValues?.answers) {
      setAnswers(initialValues?.answers?.answers || []);
    }
  }, [initialValues]);

  if (loadingValues) return <LoadingIndicator />;

  return (
    <Container>
      <Title className="research-title">{initialValues?.title}</Title>
      {questions.map((question: QuestionInterface, index: number) => {
        return (
          <React.Fragment key={`question-quiz-${index}`}>
            <Question>{question?.description}</Question>
            {question?.type === 'text' && (
              <TextAreaInput
                disabled={disabled}
                value={
                  answers?.find(
                    (answer: AnswerInterface) =>
                      answer.questionId === question.id
                  )?.answer
                }
                onChange={(e: any) =>
                  handleChangeAnswer(
                    {
                      questionId: question.id,
                      answer: e?.target?.value
                    },
                    questionsId
                  )
                }
              />
            )}
            {question?.type === 'select' &&
              question &&
              question?.alternatives && (
                <Radio.Group
                  disabled={disabled}
                  value={
                    answers?.find(
                      (answer: AnswerInterface) =>
                        answer.questionId === question.id
                    )?.answer
                  }
                  onChange={(e: any) => {
                    const id = question?.alternatives?.find(
                      (el) => el.description === e?.target?.value
                    )?.ramificationQuestionId;
                    handleChangeAnswer(
                      {
                        questionId: question.id,
                        ramificationQuestionId: id ? [id] : undefined,
                        answer: e?.target?.value
                      },
                      questionsId
                    );
                  }}
                >
                  <RadioGroupContainer>
                    {question?.alternatives.map((alternative) => {
                      return (
                        <RadioContainer>
                          <Radio
                            key={`alternative-${alternative.id}`}
                            value={alternative.description}
                          >
                            {alternative.description}
                          </Radio>
                        </RadioContainer>
                      );
                    })}
                  </RadioGroupContainer>
                </Radio.Group>
              )}
            {question?.type === 'multiple-select' &&
              question &&
              question?.alternatives && (
                <SelectRadioMultiple
                  disabled={disabled}
                  questionId={question.id}
                  alternatives={question.alternatives}
                  values={
                    answers?.find(
                      (answer: AnswerInterface) =>
                        answer.questionId === question.id
                    )?.answer as string[]
                  }
                  onChange={(answer) => {
                    handleChangeAnswer(answer, questionsId);
                  }}
                />
              )}
            {question?.type === 'range' && (
              <RangeContainer>
                {[...Array(11).keys()].map((el: any, index: number) => {
                  return (
                    <RangeOption
                      key={`range-${index}`}
                      onClick={() => {
                        if (!disabled) {
                          handleChangeAnswer(
                            {
                              questionId: question.id,
                              answer: String(el)
                            },
                            questionsId
                          );
                        }
                      }}
                      $checked={
                        answers?.find(
                          (answer: AnswerInterface) =>
                            answer.questionId === question.id
                        )?.answer === String(el)
                      }
                    >
                      {el}
                      {answers?.find(
                        (answer: AnswerInterface) =>
                          answer.questionId === question.id
                      )?.answer === String(el) && (
                        <Icon type="check-circle" theme="filled" />
                      )}
                    </RangeOption>
                  );
                })}
              </RangeContainer>
            )}
            {question?.upload && (
              <UploadContainer>
                <Upload
                  name="image"
                  listType="picture"
                  showUploadList={false}
                  accept=".jpg, .jpeg, .png, .gif"
                  fileList={[]}
                  onChange={(info: any) =>
                    handleChangeUpload(info, question.id)
                  }
                  disabled={disabled}
                >
                  <Button disabled={disabled}>
                    <Icon type="upload" /> Upload imagem
                  </Button>
                </Upload>
                <ShowQuestionImage
                  files={files}
                  questionId={question.id}
                  value={
                    answers?.find(
                      (answer: AnswerInterface) =>
                        answer.questionId === question.id
                    )?.file
                  }
                />
              </UploadContainer>
            )}
            <Divider />
          </React.Fragment>
        );
      })}
      <ButtonContainer>
        {!Boolean(initialValues?.isRequired) && (
          <AntdButton
            disabled={createAnswerResearch.loading || loading}
            onClick={() => {
              onFinish();
            }}
          >
            CANCELAR
          </AntdButton>
        )}
        {!disabled && (
          <Button
            disabled={
              handleCheckAllAnswers(questions, answers) ||
              createAnswerResearch.loading ||
              loadingValues ||
              loading
            }
            loading={createAnswerResearch.loading || loading}
            onClick={async () => {
              if (!initialValues?._id || !userId) {
                return message.error(
                  'Não foi possível salvar as respostas desta pesquisa.'
                );
              }

              if (handleCheckUploads(questions, files)) {
                return message.error(
                  'Por favor, verifique o upload de imagens das perguntas.'
                );
              }

              setLoading(true);

              const record = {
                researchId: String(initialValues?._id),
                userId,
                answers
              };

              if (files.length > 0) {
                const uploads = await Promise.all(
                  files.map(async (el) => {
                    let data: any = { ...el };
                    await execute(
                      {
                        file: el.file.file.originFileObj,
                        onSuccess: () => {},
                        onError: () => {
                          return message.error(
                            'Falha no upload do(s) arquivo(s).'
                          );
                        }
                      },
                      (fileId: string) => {
                        data.fileId = fileId;
                      }
                    );
                    return { ...data };
                  })
                );
                record.answers = record.answers.map((answer) => {
                  const exists = uploads.find(
                    (file) => file.questionId === answer.questionId
                  );
                  if (exists) {
                    return { ...answer, fileId: exists.fileId };
                  }
                  return answer;
                });
              }

              await createAnswerResearch
                .fetch({ variables: { record } })
                .then((ctx: { result?: any }) => {
                  if (ctx?.result) {
                    message.success('Respostas cadastradas com sucesso.');
                    onFinish();
                  } else {
                    message.error(
                      'Não foi possível salvar as respostas desta pesquisa.'
                    );
                  }
                })
                .finally(() => setLoading(false));
            }}
          >
            SALVAR
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};
