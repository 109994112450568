import React from 'react';
import { Bellicon } from './styles';
import { Notifications } from 'autogenerated/client/types';
import { Icon } from 'antd';
import { colors } from '@digi-tim-19/theme';

interface BellIconProps {
  isResidential?: boolean;
  notifications?: Notifications | null;
}

export const BellIcon = ({ notifications }: BellIconProps) => {
  const hasUnreadNotifications = () => {
    return notifications?.unreadIds && notifications.unreadIds.length > 0;
  };

  const getNotificationUrl = () => {
    if (
      ['/portal', '/portal/'].includes(window.location.pathname.substring(0, 8))
    ) {
      return '/portal/notificacoes';
    }
    return '/notificacoes';
  };

  return (
    <Bellicon href={getNotificationUrl()}>
      <Icon
        type="bell"
        theme="filled"
        style={{
          fontSize: '22px',
          color: colors.blue
        }}
      />
      {hasUnreadNotifications() && <div className="red-dot" />}
    </Bellicon>
  );
};
