import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Empty, Icon } from 'antd';
import { Breadcrumb } from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';

import { useClient } from '../../autogenerated/client/client';
import { EnumFileKind, Material } from '../../autogenerated/client/types';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { breadcrumbFragment } from 'utils/breadcrumbFragment';

const PageTitle = styled.p`
  color: ${colors.blue};
  font-family: TimLight;
  font-size: 28px;
  line-height: 26px;
  margin: 20px 20px 20px 0;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-top: 1.5em;
`;

const FileTitle = styled.span`
  font-size: 18px;
`;

const FileDownload = styled.a`
  background: ${(props) => props.theme.blue};
  border-radius: 2px;
  color: ${(props) => props.theme.white} !important;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
  width: fit-content;
`;

export const PageLoadFiles = () => {
  const { id }: any = useParams();
  const apiAnalyticsDownload = useClient('AnalyticsMaterialDownload');
  const getMaterials = useClient('AnalyticsMaterialView');
  const [material, setMaterial] = useState<Material>();
  const [matCategory, setMatCategory] = useState<any>({});
  const breadcrumb = useBreadcrumb(material?.title!, matCategory!);
  const download =
    typeof material?.download === 'undefined' ||
    material?.download === null ||
    material?.download;

  useEffect(() => {
    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          _id: id
        }
      })
      .then((ctx) => {
        setMaterial(ctx.result!);
        setMatCategory(ctx.result?.categories.find((item: any) => item._id));
      });
  }, [id]);

  const getDownloadUrl = (fileDownload: any) => {
    if (fileDownload?.signedUrl) return fileDownload?.signedUrl;
    return undefined;
  };

  const handleTrackDownload = (fileId: string | undefined) =>
    apiAnalyticsDownload.fetch({ variables: { fileId } });

  const files = useMemo(() => {
    return (
      material?.files?.filter((file) => {
        if (
          !download &&
          file?.extension &&
          ['pdf', 'jpg', 'jpeg', 'png', 'gif'].includes(file?.extension)
        ) {
          return false;
        }
        return file?.kind !== EnumFileKind.CardThumb;
      }) || []
    );
  }, [material?.files]);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={breadcrumb} />}>
      <>
        <PageTitle>{material?.title}</PageTitle>
        {!files.length && !getMaterials?.loading ? (
          <Empty
            description={
              material?.files?.length &&
              material?.files?.length > 1 &&
              !download
                ? 'Documentos sem permissão para download'
                : 'Não há documentos para exibir'
            }
          />
        ) : (
          <>
            {files.map((item) => {
              return (
                <FileWrapper key={item?._id}>
                  <FileTitle>{item?.title}</FileTitle>
                  <FileDownload
                    href={getDownloadUrl(item) || '#'}
                    download={item?.title}
                    onClick={() => handleTrackDownload(item?._id!)}
                  >
                    <Icon
                      type="download"
                      style={{
                        color: colors.white,
                        marginRight: 5,
                        fontSize: 16
                      }}
                    />
                    Download
                  </FileDownload>
                  {material?.link && (
                    <FileDownload
                      href={material?.link || '#'}
                      download={item?.title}
                    >
                      <Icon
                        type="link"
                        style={{
                          color: colors.white,
                          marginRight: 5,
                          fontSize: 16
                        }}
                      />
                      Link
                    </FileDownload>
                  )}
                </FileWrapper>
              );
            })}
          </>
        )}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  _id
  title
  files {
    _id
    title
    kind
    extension
    downloadUrl
    signedUrl
  }
  categories {
    ${breadcrumbFragment}
  }
`;
