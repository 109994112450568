import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { Icon } from 'antd';
import { useParams } from 'react-router';
import {
  Breadcrumb,
  LoadingIndicator,
  ShareModal,
  useMaterialActions
} from '@digi-tim-19/components';
import { colors } from '@digi-tim-19/theme';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { breadcrumbFragment } from 'utils/breadcrumbFragment';
import { useClient } from 'autogenerated/client/client';
import { Icon as IconAction } from '@digi-tim-19/components/build/main/ActionButton/ActionButton';
import { DocsEditorForm } from 'components/DocsEditor/DocsEditorForm';

const Title = styled.h2`
  color: ${colors.blue};
  font-size: 25px;
  font-family: TimBold;
  margin-bottom: 30px;
`;

const IFrameWrapper = styled.div`
  width: 100%;

  iframe {
    width: 100%;
    height: 150vh !important;
    border: none;
    overflow: auto;
  }
`;

const HTMLDownload = styled.a`
  align-items: center;
  background: ${(props) => props.theme.blue};
  border-radius: 2px;
  color: ${(props) => props.theme.white};
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase;
  padding: 5px 10px;
  width: 9rem;
  margin-right: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;

  &,
  a {
    max-height: 30px;
  }
`;

const appendToFragment = `
  files { _id kind extension signedUrl downloadUrl }
  categories {${breadcrumbFragment}}
  editor
`;

export const PageLoadHTML = () => {
  const { id }: any = useParams();
  const MaterialLikeToggle = useClient('MaterialLikeToggle');
  const actions = useMaterialActions();
  const apiAnalyticsDownload = useClient('AnalyticsMaterialDownload');
  const materialView = useClient('AnalyticsMaterialView', {
    variables: {
      _id: id
    },
    appendToFragment,
    fetchOnMount: true
  });
  const material = materialView?.result;
  const htmlData = material?.html || '';
  const editorData = material?.editor;
  const categories = material?.categories || [];
  const matCategory = categories.find((item: any) => item._id);
  const breadcrumb = useBreadcrumb(material?.title!, matCategory!);

  const handleTrackDownload = (fileId: string | undefined) =>
    apiAnalyticsDownload.fetch({ variables: { fileId } });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={breadcrumb} />}>
      <>
        <Title>{material?.title}</Title>
        {materialView?.loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!!htmlData && (
              <IFrameWrapper>{ReactHtmlParser(htmlData)}</IFrameWrapper>
            )}
            {!!editorData && !htmlData && (
              <DocsEditorForm
                files={[]}
                previewMode={true}
                initialValues={editorData}
                onChange={() => {}}
              />
            )}

            {material?._id && (
              <ButtonsContainer>
                {material?.enableSocialInteraction && (
                  <IconAction
                    isFilled={material.likes?.liked}
                    name={'heart'}
                    amount={material.likes?.count}
                    onClick={() => {
                      MaterialLikeToggle.fetch({
                        variables: { recordId: material._id }
                      });
                    }}
                  />
                )}
                <ShareModal />
                <IconAction
                  isFilled={material?.pinned}
                  name={'star'}
                  onClick={() =>
                    material?.pinned
                      ? actions.deletePin({ recordId: material._id })
                      : actions.pin({ recordId: material._id })
                  }
                />
              </ButtonsContainer>
            )}

            {material?.files?.map((item) => {
              if (item?.kind === 'card_thumb') return;
              return (
                <>
                  <HTMLDownload
                    href={item?.signedUrl || '#'}
                    download={material.title}
                    onClick={() => handleTrackDownload(item?.signedUrl || '')}
                  >
                    <Icon
                      type="download"
                      style={{ color: colors.white, marginRight: 5 }}
                    />
                    Download
                  </HTMLDownload>
                  {material.link && (
                    <HTMLDownload
                      href={material?.link || '#'}
                      download={material.title}
                    >
                      <Icon
                        type="link"
                        style={{ color: colors.white, marginRight: 5 }}
                      />
                      Acessar Link
                    </HTMLDownload>
                  )}
                </>
              );
            })}
          </>
        )}
      </>
    </PageTemplate>
  );
};
