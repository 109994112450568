import React from 'react';
import { Icon, useClient } from '@digi-tim-19/components';
import { useCurrentUser } from '@digi-tim-19/components';
import { User } from 'autogenerated/client/types';
import {
  HeaderContainerItems,
  HeaderWrapper,
  FirstDiv,
  HamburgerButton,
  SecondDiv,
  TotalPoints,
  SearchWrapper,
  ContainerTopBar,
  UserBellWrapper,
  ProfileAlertContainer,
  SVGContainer,
  SVGText,
  SearchWrapperContainer,
  BreadcrumbContainer,
  BreadCrumbItem
} from './styles';
import { Logo } from '../Desktop/Logo';
import { BellIcon } from '../Desktop/BellIcon';
import { RedeemPoints } from '../Desktop/Redeempoints';
import { ImgUserMobile } from './ImgUserMobile';
import { SearchInput } from '../Desktop/SearchInput';
import { ProfileSimulationAlert } from 'components/ProfileSimulator/ProfileSimulationAlert';
import { useHistory, useLocation } from 'react-router';
import { TourSection } from 'providers/TourProvider';
import { colors } from '@digi-tim-19/theme';

interface HeaderMobileProps {
  breadcrumb?: React.ReactNode;
  menuExpand: () => void;
  onSelect?: (term: string) => any;
  menuIsOpen: boolean;
}

export const HeaderMobile = ({
  menuExpand,
  breadcrumb,
  onSelect,
  menuIsOpen
}: HeaderMobileProps) => {
  const user = useCurrentUser()?.result as User;
  const ReconheceUserBalance = useClient('ReconheceUserBalance', {
    fetchOnMount: true
  });
  const history = useHistory();
  const location = useLocation();
  const total = ReconheceUserBalance.result?.balance;

  const handleGoBack = () => {
    if (location.pathname !== '/') {
      history.go(-1);
    }
  };

  return (
    <>
      <ProfileAlertContainer>
        <ProfileSimulationAlert />
      </ProfileAlertContainer>

      <HeaderWrapper>
        <TourSection selector="tour-profile-menu" />
        <HeaderContainerItems className="tour-profile-menu">
          <FirstDiv>
            <HamburgerButton
              onClick={() => {
                menuExpand();
              }}
            >
              {menuIsOpen ? (
                <Icon type="close" className="icon" />
              ) : (
                <Icon type="menu" className="icon" />
              )}
            </HamburgerButton>
            <Logo />
            <UserBellWrapper>
              <ImgUserMobile
                img={user?.avatar?.signedUrl || ''}
                online={true}
              />
              <BellIcon notifications={user?.notifications} />
            </UserBellWrapper>
          </FirstDiv>

          <SecondDiv>
            <TotalPoints>
              {total || 0}
              <p
                style={{
                  marginTop: -6,
                  fontFamily: 'TimBold',
                  whiteSpace: 'nowrap'
                }}
              >
                TIM Coins
              </p>
            </TotalPoints>
            <div className="position">
              <RedeemPoints />
            </div>
          </SecondDiv>
        </HeaderContainerItems>
      </HeaderWrapper>
      <ContainerTopBar menuIsOpen={menuIsOpen} breadcrumb={breadcrumb}>
        {breadcrumb && !menuIsOpen && (
          <SVGContainer onClick={handleGoBack}>
            <Icon
              type="left-circle"
              theme="filled"
              style={{
                fontSize: '20px',
                color: colors.blue
              }}
            />
            <SVGText>Voltar</SVGText>
          </SVGContainer>
        )}

        {breadcrumb && !menuIsOpen && (
          <BreadcrumbContainer>
            <BreadCrumbItem>{breadcrumb}</BreadCrumbItem>
          </BreadcrumbContainer>
        )}

        <SearchWrapperContainer
          breadcrumb={!!breadcrumb}
          menuIsOpen={menuIsOpen}
        >
          <SearchWrapper className="searchBar">
            <SearchInput onSelect={onSelect} />
          </SearchWrapper>
        </SearchWrapperContainer>
      </ContainerTopBar>
    </>
  );
};
