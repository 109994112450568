import React from 'react';
import { Button } from 'antd';
import Tour from 'reactour';
import { useHistory } from 'react-router';
import { routes } from 'config/routes';
import { useCurrentUser } from 'hooks/user/useCurrentUser';
import { userIsResidential } from 'utils/validations';

interface ContextData {
  steps: any[];
  currentSelector?: string;
  loading: boolean;
  openMenu: boolean;
  openSubmenu: boolean;
  menuParentId?: string;
  onFinishTour: Function;
  onNextStep: Function;
  onLoading: Function;
  onStartTour: Function;
}

const TourContext = React.createContext<ContextData>({
  steps: [],
  loading: false,
  openMenu: false,
  openSubmenu: false,
  onStartTour: () => {},
  onFinishTour: () => {},
  onNextStep: () => {},
  onLoading: () => {}
});

export const TourProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useCurrentUser().result;
  const [currentSelector, setCurrentSelector] = React.useState<
    string | undefined
  >(undefined);
  const [openMenu, setOpenMenu] = React.useState({
    openMenu: false,
    openSubmenu: false,
    menuParentId: undefined
  });
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const isResidential = userIsResidential(user);

  const onNextStep = (currentSelector?: string) => {
    setCurrentSelector(currentSelector);
  };

  const onLoading = (value: boolean) => {
    setLoading((_prev) => value);
  };

  const onStartTour = () => {
    setCurrentSelector(steps.corporate[0].selector);
  };

  const filterSteps = (data: any[], currentSelector?: string) => {
    if (!currentSelector) return [];
    const stepsCustomized = data.map((step, index) => {
      return {
        ...step,
        content: () => {
          const finish = Boolean(index === data.length - 1);

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <span>{step.content}</span>
              <Button
                loading={loading}
                disabled={loading}
                onClick={() => {
                  const nextStepData = data[index + 1]
                    ? data[index + 1]
                    : undefined;
                  onNextStep(
                    !finish && nextStepData ? nextStepData.selector : undefined
                  );

                  setOpenMenu({
                    openMenu: Boolean(
                      nextStepData?.openMenu || nextStepData?.openSubmenu
                    ),
                    openSubmenu: Boolean(nextStepData?.openSubmenu),
                    menuParentId: nextStepData?.menuParentId
                  });

                  if (nextStepData?.route) {
                    if (
                      ['/portal', '/'].includes(window.location.pathname) &&
                      nextStepData.route === '/'
                    ) {
                      return;
                    }

                    const hasPrefix = ['/portal', '/portal/'].includes(
                      window.location.pathname.substring(0, 8)
                    );

                    if (hasPrefix && nextStepData.route === '/') {
                      history.push('/portal');
                      return;
                    }

                    const route = String(
                      hasPrefix
                        ? `/portal${nextStepData.route}`
                        : nextStepData.route
                    ).replace(/\/\/{1,}/g, '/');

                    if (window.location.pathname !== route) {
                      history.push(route);
                    }
                  }
                }}
              >
                {loading ? 'Carregando' : finish ? 'Finalizar tour' : 'Próximo'}
              </Button>
            </div>
          );
        }
      };
    });
    return stepsCustomized.filter((el: any) => el.selector === currentSelector);
  };

  const data = React.useMemo(() => {
    return {
      ...openMenu,
      loading: loading,
      currentSelector,
      steps: filterSteps(
        isResidential ? steps.residential : steps.corporate,
        currentSelector
      ),
      onNextStep,
      onLoading,
      onStartTour,
      onFinishTour: () => {}
    };
  }, [currentSelector, openMenu, loading]);

  return <TourContext.Provider value={data}>{children}</TourContext.Provider>;
};

export const useTour = () => {
  return React.useContext(TourContext);
};

export const TourSection = ({
  selector,
  loading = false
}: {
  selector: string;
  loading?: boolean;
}) => {
  const { steps, currentSelector, onFinishTour, onLoading } = useTour();

  React.useEffect(() => {
    onLoading(loading);
  }, [loading]);

  return (
    <Tour
      badgeContent={() => 'i'}
      isOpen={Boolean(currentSelector && currentSelector === `.${selector}`)}
      steps={steps}
      disableKeyboardNavigation={true}
      onRequestClose={() => onFinishTour()}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onBeforeClose={() => (document.body.style.overflow = 'auto')}
      showCloseButton={false}
      showNavigationNumber={false}
      showButtons={false}
      disableDotsNavigation={true}
      showNavigation={false}
      disableInteraction={true}
    />
  );
};

const steps = {
  corporate: [
    {
      selector: '.tour-profile-menu',
      content:
        'Nesta seção você poderá resgatar seus TIM Coins, atualizar seus dados cadastrais passando o cursor do mouse sobre a sua foto de perfil e acessar notificações.'
    },
    {
      selector: '.tour-search-global',
      content:
        'Neste campo você poderá fazer uma busca global de documentos, vídeos e informativos em todo o portal independentemente da página que estiver.'
    },
    {
      menuId: 'menu:mobile_e_ict',
      selector: '.tour-menu-mobile-e-ict',
      content:
        'Nesta opção de menu, você acessa os materiais de apoio para o seu dia a dia, incluindo apresentações e guias sobre as soluções da TIM que estão disponíveis em portólio.',
      openMenu: true
    },
    {
      menuId: 'menu:mobile_e_ict',
      menuParentId: 'menu:mobile_e_ict',
      selector: '.tour-submenu-content',
      content:
        'Você poderá acessar os materiais de apoio clicando em uma destas opções.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:processos',
      selector: '.tour-menu-processos',
      content:
        'Nesta opção de menu, você acessa os materiais de apoio com o passo a passo de cada procedimento.',
      openMenu: true
    },
    {
      menuId: 'menu:campanhas_corporate',
      selector: '.tour-menu-campanhas-corporate',
      content:
        'Nesta opção do menu, você poderá acessar as campanhas vigentes, em apuração e/ou encerradas.',
      openMenu: true
    },
    {
      menuId: 'menu:campanhas_corporate',
      menuParentId: 'menu:campanhas_corporate',
      selector: '.tour-menu-campanhas-corporate-minhas-contestacoes',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá acessar as suas contestações em resultados de campanhas.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_corporate',
      menuParentId: 'menu:campanhas_corporate',
      selector: '.tour-menu-campanhas-corporate-campanhas-encerradas',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá visualizar os resultados das campanhas.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_corporate',
      menuParentId: 'menu:campanhas_corporate',
      selector: '.tour-menu-campanhas-corporate-campanhas-com-resultados',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá visualizar as campanhas que foram encerradas.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_corporate',
      menuParentId: 'menu:campanhas_corporate',
      selector: '.tour-menu-campanhas-corporate-campanhas-vigentes',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá acessar e participar das campanhas vigentes.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_corporate',
      selector: '.tour-campaign-card-0',
      content:
        'Ao clicar na opção "Vigentes", você será redirecionado(a) para esta listagem, onde haverão opções para fixar, compartilhar e acessar os detalhes das campanhas que você poderá participar.',
      route: routes.campanhasIncentivoVigentes.mount()
    },
    {
      menuId: 'menu:comissionamento',
      selector: '.tour-menu-comissionamento',
      content:
        'Além disso, você também pode acessar as informações relacionadas à sua meta e remuneração variável, caso seja elegível.',
      route: routes.comissionamentoParticipanteListar.mount()
    }
  ],
  residential: [
    {
      selector: '.tour-profile-menu',
      content:
        'Nesta seção você poderá resgatar seus TIM Coins, conquistados nas campanhas de incentivo, e atualizar seus dados!'
    },
    {
      selector: '.tour-search-global',
      content:
        'Neste campo você poderá fazer uma busca global de documentos, vídeos e informativos em todo o portal independentemente da página que estiver.'
    },
    {
      selector: '.tour-home-feed',
      content:
        'Aqui você conhece as novidades e interage, como em uma rede social.',
      route: routes.home.mount()
    },
    {
      menuId: 'menu:informativos',
      selector: '.tour-menu-informativos',
      content:
        'Neste menu, confira as informações enviadas para você por e-mail.',
      openMenu: true
    },
    {
      menuId: 'menu:informativos',
      selector: '.tour-material-card-0',
      content:
        'Ao clicar, você poderá acessar os conteúdos, favoritar e compartilhar usando os botões na tela.',
      route: routes.informativosResidencial.mount()
    },
    {
      menuId: 'menu:ultrabook',
      selector: '.tour-menu-ultrabook',
      content:
        'Aqui você encontra todos os documentos que vão te ajudar a vender mais, com argumentos e detalhes das nossas ofertas.',
      openMenu: true
    },
    {
      menuId: 'menu:residencial_videos',
      selector: '.tour-menu-residencial-videos',
      content:
        'Assista os conteúdos de lives, tutoriais e muitos outros vídeos.',
      openMenu: true
    },
    {
      menuId: 'menu:suporte_e_processos',
      selector: '.tour-menu-suporte-e-processos',
      content:
        'Encontre os detalhes e orientações sobre os procedimentos gerais do nosso negócio.',
      openMenu: true
    },
    {
      menuId: 'menu:residencial_comunicacao_cliente',
      selector: '.tour-menu-residencial-comunicacao-cliente',
      content:
        'Orientações e materiais para você produzir ou mostrar de forma digital ao cliente.',
      openMenu: true
    },
    {
      menuId: 'menu:favoritos',
      selector: '.tour-menu-favoritos',
      content:
        'Seus materiais marcados como favoritos podem ser encontrados rapidamente nesta opção!',
      openMenu: true
    },
    {
      menuId: 'menu:campanhas_residencial',
      selector: '.tour-menu-campanhas-residencial',
      content:
        'Nesta opção do menu, você poderá acessar as campanhas vigentes, em apuração e/ou encerradas.',
      openMenu: true
    },
    {
      menuId: 'menu:campanhas_residencial',
      menuParentId: 'menu:campanhas_residencial',
      selector: '.tour-menu-campanhas-residencial-minhas-contestacoes',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá acessar as suas contestações em resultados de campanhas.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_residencial',
      menuParentId: 'menu:campanhas_residencial',
      selector: '.tour-menu-campanhas-residencial-campanhas-encerradas',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá visualizar as campanhas encerradas.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_residencial',
      menuParentId: 'menu:campanhas_residencial',
      selector: '.tour-menu-campanhas-residencial-campanhas-com-resultados',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá visualizar os resultados das nossas campanhas.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_residencial',
      menuParentId: 'menu:campanhas_residencial',
      selector: '.tour-menu-campanhas-residencial-campanhas-vigentes',
      content:
        'Nesta opção do menu CAMPANHAS DE INCENTIVO, você poderá acessar e participar das campanhas vigentes.',
      openMenu: true,
      openSubmenu: true
    },
    {
      menuId: 'menu:campanhas_residencial',
      selector: '.tour-campaign-card-0',
      content:
        'Ao clicar na opção “VIGENTES”, você conhecerá as campanhas atuais e poderá acessar dar o aceite digital para participar.',
      route: routes.campanhasIncentivoVigentes.mount()
    },
    {
      selector: '.tour-help-container',
      content:
        'Entre em contato com nossa equipe de suporte e tire dúvidas sobre o resgate de TIM Coins, status de compras e muito mais!',
      route: routes.home.mount()
    }
  ]
};

export const clearTourClassName = (value: string) => {
  return value.replace(/\:|\_|\ /g, '-');
};
