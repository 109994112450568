import { useClient } from '../../autogenerated/client/client';

export const useCategoryCoreVideo = () => {
  const CategoryFindMany = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: {
      limit: 1000,
      filter: {
        _ids: [
          'menu:residencial_suporte',
          'menu:compara_ai',
          'menu:ultrabook',
          'menu:ver_mais:canal',
          'menu:ver_mais:tim_controle',
          'menu:residencial_comunicacao_cliente',
          'menu:mobile_e_ict',
          'menu:processos',
          'menu:suporte_e_processos',
          'menu:para_o_cliente',
          'menu:videos',
          'menu:eventos'
        ]
      }
    }
  });

  return CategoryFindMany?.result?.map((item) => ({
    label: item?.name?.toUpperCase(),
    value: item?._id
  }));
};
