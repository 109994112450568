import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  LoadingIndicator,
  useClient,
  useCurrentUser
} from '@digi-tim-19/components';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { Image } from 'components/Image/Image';
import { routes } from 'config/routes';

import {
  SectionTitle,
  RemunerationFAQ,
  RemunerationBanner,
  PDFViewer
} from './styles';
import { Result } from 'antd';

const appendToFragment = `
  _id
  title
  faq
  bannerContentFile { signedUrl }
  documentFile {
    _id
    signedUrl
    extension
    size
  }
  regulationFile {
    signedUrl
    downloadUrl
  }
  presentationFile {
    signedUrl
    downloadUrl
  }
`;

const configPage = {
  pageName: 'Remuneração variável e metas',
  icon: 'inbox',
  description: 'Visualização do documento de remuneração variável e metas.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Comissionamento' },
    {
      title: 'Remuneração variável e metas',
      link: routes.comissionamentoParticipanteListar.mount()
    },
    { title: 'Visualizar' }
  ]
};

export const RemuneracaoVisualizar = (props: any) => {
  const history = useHistory();
  const user = useCurrentUser();
  const { params } = useRouteMatch<{ id: string }>();
  const getRemuneration = useClient('RemunerationFindById', {
    variables: { _id: params?.id! },
    appendToFragment,
    fetchOnMount: true
  });
  const fileSize = getRemuneration?.result?.documentFile?.size || 1;

  React.useEffect(() => {
    if (user?.result && params?.id && !props?.location?.state?.accepted) {
      const hasAccepted = user?.result?.acceptedTerms?.find(
        (item) => item?.term === `RemunerationRegulation-${params?.id}`
      );

      if (!hasAccepted) {
        history.push(routes.comissionamentoParticipanteListar.mount());
      }
    }
  }, [user?.result, params?.id]);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {getRemuneration.loading && <LoadingIndicator />}
        {!getRemuneration.loading && (
          <>
            <SectionTitle>{getRemuneration?.result?.title}</SectionTitle>

            {!!getRemuneration?.result?.bannerContentFile?.signedUrl && (
              <RemunerationBanner>
                <Image
                  href={getRemuneration?.result?.videoLink || undefined}
                  src={
                    getRemuneration?.result?.bannerContentFile?.signedUrl || ''
                  }
                  alt={getRemuneration?.result?.title || ''}
                />
              </RemunerationBanner>
            )}

            {getRemuneration?.result?.documentFile?.extension === 'pdf' && (
              <>
                {fileSize < 2 * Math.pow(10, 7) ? (
                  <PDFViewer key={getRemuneration?.result?.documentFile?._id}>
                    <iframe
                      src={
                        getRemuneration?.result?.documentFile?.signedUrl
                          ? `${getRemuneration?.result?.documentFile?.signedUrl}#toolbar=0`
                          : ''
                      }
                    />
                  </PDFViewer>
                ) : (
                  <Result
                    title="Arquivo muito grande para abrir no visualizador"
                    subTitle="Por favor, faça o download do documento no botão abaixo"
                  />
                )}
              </>
            )}

            {getRemuneration?.result?.faq !== '<p></p>\n' && (
              <>
                <SectionTitle>FAQ</SectionTitle>
                <RemunerationFAQ
                  dangerouslySetInnerHTML={{
                    __html: getRemuneration?.result?.faq || ''
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    </PageTemplate>
  );
};
