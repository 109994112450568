import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Pagination, Empty } from 'antd';
import {
  Breadcrumb,
  CardInterno,
  SelectFilter,
  TitleDescription,
  MaybeMaterialList,
  LoadingIndicator,
  DatePickerRange,
  ListGrid,
  parseCardsData,
  Icon,
  MaterialProvider,
  ShareModal,
  useMaterialActions
} from '@digi-tim-19/components';
import { Icon as IconAction } from '@digi-tim-19/components/build/main/ActionButton/ActionButton';

import { routes } from 'config/routes';
import { useClient } from 'autogenerated/client/client';
import { PageTemplate } from 'components/Layout/PageTemplate';
import {
  EnumMaterialKind,
  EnumMaterialStatus,
  SortFindManyMaterialInput,
  DateRange
} from 'autogenerated/client/types';
import { getAccessButton, accessMaterial } from 'utils/material';

import { FNoticias } from './fragments';
import {
  StyledContent,
  OrderWrapper,
  CardsVisual,
  ChooseVisualWrapper
} from './styles';

enum ListMode {
  card,
  list
}

const configPage = {
  pageName: 'Notícias',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Comunicação' },
    { title: 'Notícias' }
  ],
  icon: 'read',
  perPage: 20
};

export const Noticias = () => {
  const MaterialPagination = useClient('MaterialPagination');

  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>();
  const [visualMode, setVisualMode] = useState<ListMode>(ListMode.card);
  const [validityFilter, setValidityFilter] = useState<DateRange>();
  const [sort, setSort] = useState<SortFindManyMaterialInput>(
    SortFindManyMaterialInput.CreatedatDesc
  );

  useEffect(() => {
    MaterialPagination.fetch({
      appendToFragment: FNoticias,
      variables: {
        filter: {
          status: EnumMaterialStatus.Approved,
          kind: EnumMaterialKind.News,
          publishedAtRange: validityFilter
        },
        page,
        perPage: configPage.perPage,
        sort
      }
    }).then((ctx) => {
      const materials = ctx.result?.items;
      if (materials) {
        setItems(materials);
      }
    });
  }, [validityFilter, sort]);
  const isLoading = MaterialPagination.loading;
  const materialsCount = MaterialPagination.result?.count;

  const selectsOrders = useMemo(() => {
    return [
      {
        defaultValue: 'Ordenar Por',
        action: (value: SortFindManyMaterialInput) => {
          setSort(value);
          setPage(1);
        },
        options: [
          {
            name: 'Mais antigo',
            value: SortFindManyMaterialInput.CreatedatAsc
          },
          {
            name: 'Mais recente',
            value: SortFindManyMaterialInput.CreatedatDesc
          }
        ]
      }
    ];
  }, []);

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description="Consulte as notícias cadastradas."
        />

        <StyledContent>
          <OrderWrapper>
            <SelectFilter selects={selectsOrders} />

            <DatePickerRange
              style={{
                width: '300px'
              }}
              placeholderStart="Filtrar período"
              placeholderEnd=""
              showTime={true}
              onChange={(values: any[]) => {
                if (values.length === 0) setValidityFilter(undefined);
              }}
              onOk={(value: any) => {
                const dateISOArr = value.map((item: any) =>
                  moment(item).toISOString()
                );
                setValidityFilter({ start: dateISOArr[0], end: dateISOArr[1] });
              }}
            />

            <ChooseVisualWrapper>
              <Icon
                onClick={() => {
                  setVisualMode(ListMode.card);
                  setPage(1);
                }}
                type="appstore"
                style={{ fontSize: '35px' }}
              />
              <Icon
                onClick={() => {
                  setVisualMode(ListMode.list);
                  setPage(1);
                }}
                type="unordered-list"
                style={{ fontSize: '35px' }}
              />
            </ChooseVisualWrapper>
          </OrderWrapper>

          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <CardsVisual>
              <InnerPageData
                mode={visualMode}
                materials={items as MaybeMaterialList}
              />
              <Pagination
                pageSize={configPage.perPage}
                current={page}
                total={materialsCount || 0}
                onChange={(page) => setPage(page)}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </CardsVisual>
          )}

          {!isLoading && !items?.length && <Empty />}
        </StyledContent>
      </>
    </PageTemplate>
  );
};

const InnerPageData = (props: {
  mode: ListMode;
  materials: MaybeMaterialList;
}) => {
  const history = useHistory();
  const actions = useMaterialActions();

  const materials = props.materials;

  const data = useMemo(() => {
    return parseCardsData(materials);
  }, [materials]);

  if (props.mode === ListMode.card) {
    return (
      <CardInterno
        items={materials}
        defaultImage={'/defaultImages/default-card-thumb.png'}
        actions={(material) => {
          return {
            pin: true,
            share: true,
            accessButton: getAccessButton(material, history, routes)
          };
        }}
      />
    );
  } else {
    const items = data.map((el) => {
      const material = el.material || {};

      return {
        noDescription: true,
        ...el.cardData,
        defaultImage: '/defaultImages/default-card-thumb.png',
        renderShare: () => (
          <MaterialProvider material={material as any}>
            <ShareModal />
          </MaterialProvider>
        ),
        renderPin: () => (
          <MaterialProvider material={material as any}>
            <IconAction
              isFilled={material.pinned}
              name={'pushpin'}
              onClick={() => {
                actions.pin({ recordId: material._id });
              }}
            />
          </MaterialProvider>
        ),
        actions: {
          pin: true,
          share: true,
          access: true,
          onAccess: () => {
            accessMaterial(material, history, routes);
          },
          onPin: () => {
            actions.pin({ recordId: el.material._id });
          }
        }
      };
    });

    return <ListGrid items={items} />;
  }
};
