import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  Button,
  LoadingIndicator,
  Table,
  TitleDescription,
  useClient
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import {
  FilterContainer,
  Icon,
  Modal,
  ModalAnswer,
  ModalQuestion,
  Wrapper
} from './styles';
import { useHistory, useRouteMatch } from 'react-router';
import moment from 'moment';
import { message, Icon as AntdIcon } from 'antd';
import { downloadXLSX } from 'utils/downloadXlsx';
import { AnswerInterface, QuestionInterface } from '../Form/FormAnswer';
import { SortFindManyAnswerResearchInput } from 'autogenerated/client/types';

interface ModalUserAnswersProps {
  questions?: QuestionInterface[];
  answers?: AnswerInterface[];
}

const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    userId
    researchId
    answers
    user {
      name
      channels
      matricula
    }
    research {
      title
      questions
    }
    createdAt
    updatedAt
  }
`;

const ModalUserAnswers = ({
  questions = [],
  answers = []
}: ModalUserAnswersProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleQuestionAnswer = (
    questions: QuestionInterface[],
    answers: AnswerInterface[]
  ) => {
    return questions
      .map((question) => {
        const questionAnswer = answers.find(
          (answer) => answer.questionId === question.id
        );
        return {
          question: question.description,
          answer: questionAnswer?.answer,
          fileUrl: questionAnswer?.file?.signedUrl
        };
      })
      .filter((el) => el?.answer);
  };

  return (
    <React.Fragment>
      <Icon type="eye" onClick={() => setOpen(true)} />
      <Modal
        title="Perguntas e respostas"
        visible={open}
        okText="Ok"
        cancelText="Fechar"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        {handleQuestionAnswer(questions, answers)?.map((el: any) => {
          return (
            <React.Fragment>
              <ModalQuestion>Pergunta: {el.question}</ModalQuestion>
              <ModalAnswer>
                Resposta:{' '}
                {Array.isArray(el.answer) ? el.answer.join('; ') : el.answer}
                {el?.fileUrl && (
                  <img
                    src={el?.fileUrl}
                    alt="Imagem da pergunta"
                    style={{
                      margin: '10px auto',
                      width: '100%',
                      height: '300px'
                    }}
                  />
                )}
              </ModalAnswer>
            </React.Fragment>
          );
        })}
      </Modal>
    </React.Fragment>
  );
};

const columns = [
  {
    title: 'Pesquisa',
    dataIndex: 'research',
    key: 'research',
    render: (data: any) => <p>{data?.title || ''}</p>
  },
  {
    title: 'Usuário',
    dataIndex: 'user',
    key: 'user',
    render: (data: any) => <p>{data?.name || ''}</p>
  },
  {
    title: 'Matrícula',
    dataIndex: 'user',
    key: 'matricula-user',
    render: (data: any) => <p>{data?.matricula || ''}</p>
  },
  {
    title: 'Canal',
    dataIndex: 'user',
    key: 'channel-user',
    render: (data: any) => <p>{data?.channels?.join() || ''}</p>
  },
  {
    title: 'Data da resposta',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => (
      <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>
    )
  },
  {
    title: 'Ações',
    key: 'action',
    render: (_: any, record: any) => (
      <ModalUserAnswers
        questions={record?.research?.questions}
        answers={record?.answers}
      />
    )
  }
];

export const GerenciarAnswerUserList = () => {
  const history = useHistory();
  const { params }: any = useRouteMatch();
  const getData = useClient('AnswerResearchPagination');
  const downloadAnswers = useClient('ResearchAnswersDownload');
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState<any>([]);
  const perPage = 10;
  const total = getData?.result?.pageInfo?.itemCount || 0;

  const handleGetData = (researchId: string) => {
    getData
      .fetch({
        appendToFragment,
        variables: {
          page: page,
          perPage: perPage,
          sort: SortFindManyAnswerResearchInput.UpdatedatDesc,
          filter: {
            researchId
          }
        }
      })
      .then((response: any) => {
        const items = response?.result?.items?.map((el: any) => {
          return {
            ...el,
            onRead: () => {
              history.push(routes.researchRespostasUsuarios.mount(el?._id));
            }
          };
        });

        setData([...items]);
      });
  };

  const handleDownloadBase = () => {
    downloadAnswers
      .fetch({ variables: { researchId: params?.id } })
      .then((response: any) => {
        if (!response?.result?.data || response?.result?.data?.length === 0) {
          message.error('Não há registro para download de planilha');
          return;
        }

        downloadXLSX(
          'pesquisa-respostas',
          response?.result?.schema,
          response?.result?.data
        );
      });
  };

  React.useEffect(() => {
    handleGetData(params.id);
  }, [page, params?.id]);

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={[
            { title: 'Home', link: routes.home.mount() },
            { title: 'Gerenciar conteúdo', link: routes.home.mount() },
            {
              title: 'Respostas das pesquisas',
              link: routes.researchRespostas.mount()
            },
            { title: 'Respostas da pesquisa por usuário' },
            { title: 'Listar' }
          ]}
        />
      }
    >
      <React.Fragment>
        <Wrapper>
          <TitleDescription
            iconType={'table'}
            title="Respostas das pesquisas"
            description="Listar/consultar respostas da pesquisa por usuário"
          />
        </Wrapper>
        <FilterContainer>
          <Button
            loading={downloadAnswers.loading}
            style={{ width: 'max-content' }}
            onClick={() => handleDownloadBase()}
          >
            <AntdIcon type="file-excel" />
            EXPORTAR RESPOSTAS
          </Button>
        </FilterContainer>
        {getData.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: perPage,
              current: page,
              total: total!,
              onChange: (e: any) => setPage(e)
            }}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
