import React from 'react';
import { useRouteMatch } from 'react-router';
import { message } from 'antd';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  History
} from '@digi-tim-19/components';

import {
  EnumMaterialKind,
  EnumMaterialStatus
} from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { useCurrentUser } from '../../../hooks/user/useCurrentUser';
import { routes } from '../../../config/routes';

import { Form } from '../FormManage';
import { EditarFragment } from '../fragments';

export const DocumentosEditar = () => {
  const { params }: any = useRouteMatch();
  const currentUser = useCurrentUser();
  const material = useMaterial(params?.id, EditarFragment);

  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const canUpdateStatus = !!currentUser.result?.roles.find(
    (role) => role === 'update_status_material'
  );

  const configPage = {
    pageName: 'Documentos',
    icon: 'inbox',
    description: 'Consulta e cadastramento de documentos',
    kind: EnumMaterialKind.Document,
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Documentos', link: routes.documentosGerenciar.mount() },
      { title: 'Editar', link: '' }
    ],
    routeList: routes.documentosGerenciar.mount()
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {material.result && (
          <>
            <ProviderSelecionarPublico>
              <Form initialValues={material.result} />
            </ProviderSelecionarPublico>
            <History
              item={{
                status: material.result.status || '',
                history: material.result.history?.items || []
              }}
              canUpdateStatus={canUpdateStatus}
              onApproved={() => {
                const record = { status: EnumMaterialStatus.Approved };

                MaterialUpdateOne.fetch({
                  variables: { record, filter: { _id: material.result?._id } },
                  afterMutate: /^Material/
                }).then(() => {
                  message.success('Aprovado com sucesso!');
                });
              }}
              onDisapproved={() => {
                const record = { status: EnumMaterialStatus.Cancelled };

                MaterialUpdateOne.fetch({
                  variables: { record, filter: { _id: material.result?._id } },
                  afterMutate: /^Material/
                }).then(() => {
                  message.success('Documento reprovado!');
                });
              }}
            />
          </>
        )}
      </>
    </PageTemplate>
  );
};
