import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  UserProfileContainer,
  DropdownMenu,
  MenuItem,
  CustomModalDesktop,
  UserInfoContainer
} from './styles';
import UserProfileImage from '../images/user.png';
import { useCurrentUser } from '@digi-tim-19/components';
import { User } from 'autogenerated/client/types';
import { Icon, Tooltip } from 'antd';
import { UserEmailSecondary } from 'components/UserEmailSecondary/UserEmailSecondary';
import { UserEditProfile } from 'components/UserEditProfile/UserEditProfile';
import { useHistory } from 'react-router';
import { ProfileSimulator } from 'components/ProfileSimulator/ProfileSimulator';
import { TourSection, useTour } from 'providers/TourProvider';
import cx from 'classnames';
import { ModalTerm } from 'pages/Home/ModalTerm';

interface MiniProfileProps {
  id?: string;
  name?: string;
  profession?: string;
  points?: number;
  img?: string;
  alt?: string;
  online?: boolean;
}

export const MiniUserProfile = ({
  points = 0,
  name,
  profession,
  img,
  alt,
  online = false
}: MiniProfileProps) => {
  const { currentSelector } = useTour();
  const [modalEditProfile, setModalEditProfile] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);
  const [imgUser, setImgUser] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isNameTruncated, setIsNameTruncated] = useState(false);
  const history = useHistory();
  const { result: user } = useCurrentUser() as { result: User };
  const dropdownRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const disabledUser = user?.disabled || user?.disabledWithPoints;

  useEffect(() => {
    if (img) {
      setImgUser(img || UserProfileImage);
    }
  }, [img]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (nameRef.current) {
      const isTruncated =
        nameRef.current.scrollWidth > nameRef.current.clientWidth;
      setIsNameTruncated(isTruncated);
    }
  }, [name]);

  const toggleEditProfileModal = () => {
    setModalEditProfile((prevState) => !prevState);
    setShowDropDown(false);
  };

  const openModal = () => {
    setModalVisible(true);
    setShowDropDown(false);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleImageEdit = (editedImage: string) => {
    setImgUser(editedImage);
  };

  const handleOpenCloseDropDownTour = (value: boolean) => {
    setShowDropDown(value);
  };

  const getBalanceUrl = () => {
    if (
      ['/portal', '/portal/'].includes(window.location.pathname.substring(0, 8))
    ) {
      return '/portal/informacoes-extrato';
    }
    return '/informacoes-extrato';
  };

  React.useEffect(() => {
    handleOpenCloseDropDownTour(currentSelector === '.tour-profile-options');
  }, [currentSelector]);

  return (
    <Container>
      <UserInfoContainer>
        <Tooltip title={isNameTruncated ? name : undefined}>
          <p ref={nameRef}>{name}</p>
        </Tooltip>
        <p>{profession}</p>
        <span>{points} TIM Coins</span>
      </UserInfoContainer>
      <UserProfileContainer
        ref={dropdownRef}
        onMouseEnter={() => {
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
          setShowDropDown(true);
        }}
        onMouseLeave={() => {
          timeoutId.current = setTimeout(() => {
            setShowDropDown(false);
          }, 300);
        }}
      >
        <img
          src={imgUser}
          alt={alt}
          style={{ height: '50px' }}
          onError={() => setImgUser(UserProfileImage)}
        />
        {online && <div className="online-indicator"></div>}

        {!user?.emailSecondary && (
          <UserEmailSecondary hasEmailSecondary={!!user?.emailSecondary} />
        )}

        {modalEditProfile && user && (
          <UserEditProfile
            modalVisible={modalEditProfile}
            user={user}
            setVisible={toggleEditProfileModal}
            onImageEdit={handleImageEdit}
          />
        )}

        {modalTerm && (
          <ModalTerm
            onClose={() => setModalTerm(false)}
            onOk={() => {}}
            showOkButton={false}
          />
        )}

        {showDropDown && (
          <div>
            <TourSection selector="tour-profile-options" />
            <DropdownMenu
              className={
                showDropDown
                  ? cx('open tour-profile-options')
                  : 'tour-profile-options'
              }
            >
              {!disabledUser && (
                <MenuItem onClick={openModal}>
                  <Icon type="eye" />
                  Ver como
                </MenuItem>
              )}
              <a href={getBalanceUrl()}>
                <MenuItem>
                  <Icon type="file-text" />
                  Extrato Completo
                </MenuItem>
              </a>
              {!disabledUser && (
                <MenuItem onClick={toggleEditProfileModal}>
                  <Icon type="edit" />
                  Editar Perfil
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setModalTerm(true);
                  setShowDropDown(false);
                }}
              >
                <Icon type="audit" />
                Termos de uso
              </MenuItem>
            </DropdownMenu>
          </div>
        )}
      </UserProfileContainer>

      {
        <CustomModalDesktop
          className="CostumModal"
          footer={null}
          closable={false}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
        >
          <div>
            <ProfileSimulator closeModal={closeModal} />
          </div>
        </CustomModalDesktop>
      }
    </Container>
  );
};
