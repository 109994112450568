export enum MenuCategories {
  mobileICT = 'menu:mobile_e_ict',
  paraOCliente = 'menu:para_o_cliente',
  processos = 'menu:processos',
  video = 'video_playlist',
  eventos = 'menu:eventos'
}

export const MenuCategoriesOptions = [
  {
    name: 'Todas',
    value: ''
  },
  {
    name: 'Mobile & ICT',
    value: MenuCategories.mobileICT
  },
  {
    name: 'Processos',
    value: MenuCategories.processos
  },
  {
    name: 'Para o Cliente',
    value: MenuCategories.paraOCliente
  },
  {
    name: 'Eventos',
    value: MenuCategories.eventos
  }
];
