import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .selecionar-profile {
    justify-content: flex-start !important;
    margin-top: 8px !important;
  }

  .ant-calendar-picker {
    width: 100% !important;
  }

  .ant-select-selection__placeholder {
    left: 9px;
  }

  .Footer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    .FieldWrapper:nth-of-type(1) {
      max-width: 210px;
      margin-right: 15px;
    }
    .FieldWrapper:nth-of-type(2) {
      max-width: 210px;
      margin-right: 15px;
      position: relative;
    }
  }
  .FieldWrapper:nth-of-type(1) {
    align-items: center !important;
    justify-content: flex-start;
    flex-wrap: wrap;
    .name-categories {
      margin-left: 15px;
    }
  }

  .ant-divider-horizontal {
    margin-top: 5px !important;
    margin-bottom: 15px !important;
  }

  .ant-radio-group {
    width: 100% !important;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
`;
