import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Icon, message } from 'antd';
import { formatDate, isStringNumber } from '@digi-tim-19/utils/build';
import {
  Breadcrumb,
  TitleDescription,
  Button as ButtonComponents
} from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { CleanButton, FilterContainer } from '../../../components/PageFilter';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import { SortFindManyRemunerationInput } from '../../../autogenerated/client/types';
import useDebounce from 'hooks/useDebounce';
import { DocumentsListWrapper, Button, Search } from './styles';
import { columns } from './columns';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { downloadXLSX } from 'utils/downloadXlsx';

const fragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  
  items {
    _id
    remunerationId
    title
    owner
    status
    user {
      name
    }
    remunerationValidity {
      start
      end
    }
    updatedAt
  }
`;

const reportFragment = `
  name
  region
  channels
  roleGroup
  title
  dateAccepted
`;

const configPage = {
  pageName: 'Remuneração variável e metas',
  icon: 'inbox',
  description: 'Cadastramento de remuneração variável e metas.',
  messageDeletedSuccess: 'Documento sobre remuneração cancelado com sucesso',
  kind: 'remuneration',
  textButtonNew: 'Cadastrar Remuneração',
  routeNew: routes.comissionamentoCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo', link: routes.home.mount() },
    { title: 'Incentivo' },
    {
      title: 'Remuneração variável e metas',
      link: routes.comissionamentoListar.mount()
    }
  ],
  perPage: 10
};

export const RemuneracoesListar = () => {
  const history = useHistory();
  const getRemunerations = useClient('RemunerationPagination');
  const RemunerationRemoveById = useClient('RemunerationRemoveById');
  const { loading, fetch } = useClient('ReportRemuneration', {
    fragment: reportFragment
  });
  const [resultRemunerations, setResultRemunerations] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [updateTable, setUpdateTable] = useState('');
  const [filterSearch, setFilterSearch] = useState<string>();
  const [isTitleSearch, setIsTitleSearch] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(filterSearch, 500);
  const total = getRemunerations?.result?.pageInfo.itemCount;

  useEffect(() => {
    getRemunerations
      .fetch({
        appendToFragment: fragment,
        variables: {
          page: page,
          perPage: configPage.perPage,
          filter: {
            remunerationId:
              !isTitleSearch && debouncedSearchTerm
                ? +debouncedSearchTerm
                : undefined,
            title: (isTitleSearch && debouncedSearchTerm) || undefined
          },
          sort: filterSearch
            ? undefined
            : SortFindManyRemunerationInput.UpdatedatDesc
        }
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items) {
          setResultRemunerations(ctx.result.items);
        }
      });
  }, [page, debouncedSearchTerm, updateTable]); //eslint-disable-line

  const resetFilters = () => {
    setPage(1);
    setFilterSearch(undefined);
  };

  const data = resultRemunerations.map((remuneration: any) => {
    const validity = `${formatDate(
      remuneration?.remunerationValidity?.start,
      'DD/MM/YY'
    )}
      até ${formatDate(remuneration?.remunerationValidity?.end, 'DD/MM/YY')}`;

    return {
      key: remuneration?._id,
      id: remuneration?.remunerationId,
      title: remuneration?.title,
      updatedAt: formatDate(remuneration?.updatedAt, 'DD/MM/YY'),
      author: remuneration?.user?.name,
      status: remuneration?.status,
      validity,
      category: 'Remuneração variável e metas',
      hideOnRead: true,
      onEdit: () =>
        history.push(routes.comissionamentoEditar.mount(remuneration?._id)),
      onDelete: () => {
        RemunerationRemoveById.fetch({
          variables: { _id: remuneration?._id },
          afterMutate: /^Remuneration/
        }).then((ctx) => {
          message.success(configPage.messageDeletedSuccess);
          setUpdateTable(ctx.result?.recordId);
        });
      }
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
      if (e.length === 6 && isStringNumber(e)) {
        return setIsTitleSearch(false);
      }
      return setIsTitleSearch(true);
    },
    [data]
  );

  const handleDownloadReport = async () => {
    const { result } = await fetch();
    if (result && result?.length > 0) {
      let schema: any = {
        dateAccepted: { label: 'Data de Aceite' },
        title: { label: 'Título Remuneração' },
        name: { label: 'Nome' },
        region: { label: 'Regional' },
        roleGroup: { label: 'Diretoria' },
        channels: { label: 'Canal' }
      };

      downloadXLSX('relatório-aceites-remunerações', schema, result);
    } else {
      message.info('Não há registros para download da planilha.');
    }
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <DocumentsListWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <FilterContainer>
          <Button to={configPage.routeNew}>{configPage.textButtonNew}</Button>
          <ButtonComponents
            loading={loading}
            disabled={loading}
            onClick={() => handleDownloadReport()}
          >
            {!loading && <Icon type="file-excel" />}
            Relatório de aceites
          </ButtonComponents>
          <Search
            placeholder="Buscar por título ou ID"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e: any) => {
              setPage(1);
              onSearch(e.target.value);
            }}
          />
          <CleanButton onClick={() => resetFilters()}>
            Limpar filtros
          </CleanButton>
        </FilterContainer>
        <TablePagination
          loading={getRemunerations.loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: configPage.perPage,
            current: page,
            total: total!,
            onChange: (e: any) => setPage(e)
          }}
        />
      </DocumentsListWrapper>
    </PageTemplate>
  );
};
