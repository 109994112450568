import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ModalTerm } from 'pages/Home/ModalTerm';
import { Banner } from 'pages/Home/Banner';
import { CardsHome } from 'pages/Home/CardsHome';
import { useClient } from 'autogenerated/client/client';
import { useCurrentUser } from 'hooks/user/useCurrentUser';
import { UserEditProfile } from 'components/UserEditProfile/UserEditProfile';
import { User } from 'autogenerated/client/types';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { MainSocialFeed } from 'components/Layout/MainSocialFeed';
import { useHistory } from 'react-router';
import { TourSection, useTour } from 'providers/TourProvider';
import { Modal as AntdModal } from 'antd';
import { userIsResidential } from 'utils/validations';
import { AnswerResearchModal } from 'pages/Research/Form/AnswerResearchModal';

const ContentHome = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SocialFeedContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Modal = styled(AntdModal)`
  .ant-modal-body {
    padding: 10px 24px;
  }

  .ant-modal-body p {
    margin: 5px 0px 0px;
  }

  .ant-modal-body p span {
    font-style: italic;
    font-size: 10pt;
  }
`;

// Div sem conteúdo, apenas para fazer o foco do tour funcionar no botão do movidesk
const TourHelpContainer = styled.div`
  width: 70px;
  height: 70px;
  bottom: 12px;
  right: 12px;
  position: fixed;
  z-index: 999;
`;

export const Home = () => {
  const user = useCurrentUser();
  const history = useHistory();
  const { onStartTour, loading, currentSelector } = useTour();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(true);
  const [showTourModal, setShowTourModal] = useState('closed');
  const [showResearchModal, setShowResearchModal] = useState('start');
  const [sendLogPCSInfo, setSendLogPCSInfo] = useState(false);
  const saveLoginAnalyticsPCS = useClient('UserSaveLoginAnalyticsPCS');
  const UserUpdate = useClient('UserTourMutation');
  const ResearchByPage = useClient('ResearchByPage', {
    fetchOnMount: true,
    variables: {
      page: 'home'
    }
  });
  const isResidential = userIsResidential(user?.result);

  const handleRedirectToUrl = () => {
    const returnTo = sessionStorage.getItem('returnTo') || '';
    if (returnTo !== '') {
      sessionStorage.removeItem('returnTo');
      history.push(
        window.location.pathname === '/portal' ? `/portal${returnTo}` : returnTo
      );
    }
  };

  useEffect(() => {
    // Ordem de exibição:
    // 1 - Regulamento de primeiro acesso;
    // 2 - Alteração de e-mail secundário se for igual ao primário;
    // 3 - Pesquisas (Se houverem, uma ou mais);
    // 4 - Tour;
    // 5 - RedirectToUrl para uma página somente no caso do usuário acessar uma URL e não estava autenticado para acessar;
    if (user.data) {
      const hasSecondaryEmail = !!user.data.emailSecondary;
      const isTimBrasil =
        !!user.data.emailSecondary?.includes('@timbrasil.com.br');
      const isSameCorporateEmail = user.data.email === user.data.emailSecondary;
      const showModal =
        !hasSecondaryEmail || isTimBrasil || isSameCorporateEmail;
      const hasAcceptedRules = Boolean(
        user?.result?.acceptedTerms?.find(
          (item) => item?.term === 'FirstAccessRules'
        )
      );
      const hasResearch = Boolean(ResearchByPage?.result?.questions);
      setShowTermsModal(!hasAcceptedRules);
      setShowUpdateModal(showModal);
      if (!showModal && hasAcceptedRules && !ResearchByPage?.loading) {
        setShowResearchModal((prevState) =>
          prevState === 'start' && hasResearch ? 'open' : prevState
        );
        if (
          showTourModal === 'closed' &&
          !user?.loading &&
          !user?.result?.tour &&
          !hasResearch
        ) {
          setShowTourModal('opened');
        } else if (!hasResearch) {
          handleRedirectToUrl();
        }
      }
    }
  }, [
    user,
    ResearchByPage?.result?.questions,
    showTermsModal,
    showUpdateModal,
    showTourModal
  ]);

  useEffect(() => {
    if (!sendLogPCSInfo) {
      saveLogLoginPCS();
    }
  }, [sendLogPCSInfo]);

  async function saveLogLoginPCS() {
    setSendLogPCSInfo(true);

    await saveLoginAnalyticsPCS.fetch().then(() => {
      //console.log('save login pcs');
    });
  }

  const onRegisterUserTour = (_id?: string) => {
    if (!_id) return;
    UserUpdate.fetch({
      variables: {
        tour: new Date().toISOString()
      },
      afterMutate: /^User/
    }).catch(() => {});
  };

  return (
    <>
      <PageTemplate>
        <>
          {Boolean(showTermsModal && !user?.loading) && (
            <ModalTerm
              onOk={() => {
                setShowTermsModal(false);
              }}
            />
          )}
          {Boolean(user.data && !showTermsModal && showUpdateModal) && (
            <UserEditProfile
              modalVisible={showUpdateModal}
              user={user.data as User}
              setImgUser={() => {}}
              setVisible={() => {}}
            />
          )}
          {Boolean(
            showResearchModal === 'open' && ResearchByPage?.result?.questions
          ) && (
            <AnswerResearchModal
              values={ResearchByPage?.result}
              loading={ResearchByPage?.loading}
              open={showResearchModal === 'open'}
              onClose={() => setShowResearchModal('close')}
              onFinish={() => {
                ResearchByPage.refetch();
              }}
            />
          )}
          <Modal
            closable={false}
            title="Tour Pra Conectar"
            visible={Boolean(showTourModal === 'opened')}
            onOk={() => {
              onStartTour();
              setShowTourModal('started');
              onRegisterUserTour(user?.result?._id);
            }}
            okText="Iniciar tour"
            okButtonProps={{
              loading: UserUpdate.loading || loading,
              disabled: UserUpdate.loading || loading
            }}
            cancelButtonProps={{
              hidden: true
            }}
          >
            {!isResidential ? (
              <>
                <h4>
                  Bem vindo(a) ao tour pelo Portal Pra Conectar! Você
                  visualizará os seguintes itens durante o tour:
                </h4>
                <p>
                  <span>
                    - Como acessar funcionalidades do seu perfil e TIM Coins;
                  </span>
                </p>
                <p>
                  <span>
                    - Como fazer uma busca global de materiais, informativos e
                    vídeos;
                  </span>
                </p>
                <p>
                  <span>- Onde acessar os Processos disponíveis;</span>
                </p>
                <p>
                  <span>
                    - Onde encontrar materiais de apoio para as soluções de
                    portfólio;
                  </span>
                </p>
                <p>
                  <span>
                    - Como visualizar e participar das Campanhas de Incentivos.
                  </span>
                </p>
              </>
            ) : (
              <>
                <h4>
                  Desejamos boas-vindas ao Pra Conectar, seu portal de apoio com
                  todas as informações para você vender mais. Vamos fazer um
                  tour e conhecer o que você encontra por aqui?
                </h4>
              </>
            )}
          </Modal>
          <Banner />
          <ContentHome>
            <CardsHome />
          </ContentHome>
          <TourSection selector="tour-home-feed" />
          {isResidential && (
            <SocialFeedContainer className="tour-home-feed">
              <MainSocialFeed />
            </SocialFeedContainer>
          )}
        </>
        <TourSection selector="tour-help-container" />
        <TourHelpContainer
          className="tour-help-container"
          style={{
            display:
              currentSelector === '.tour-help-container' ? 'block' : 'none'
          }}
        />
      </PageTemplate>
    </>
  );
};
