import React from 'react';
import { Modal } from 'antd';
import { useClient } from '../../autogenerated/client/client';

interface Props {
  onOk: () => void;
  onClose?: () => void;
  showOkButton?: boolean;
}

export const ModalTerm = ({ onOk, onClose, showOkButton = true }: Props) => {
  const mutationAcceptTerm = useClient('UserAcceptTerm', {
    variables: { term: 'FirstAccessRules' },
    afterMutate: /^User/
  });

  return (
    <Modal
      title="Regras de convivência:"
      okText="Aceito"
      onOk={() => {
        mutationAcceptTerm.fetch().then(() => onOk());
      }}
      cancelText={'Fechar'}
      onCancel={() => {
        if (onClose) onClose();
      }}
      visible={true}
      closable={onClose ? true : false}
      style={{
        maxWidth: '80%',
        top: '15px'
      }}
      width="auto"
      okButtonProps={{
        loading: mutationAcceptTerm.loading,
        hidden: !showOkButton
      }}
      cancelButtonProps={{
        hidden: showOkButton
      }}
    >
      <p>
        Pra Conectar com todos de maneira saudável e respeitosa, lembre-se das
        regras abaixo:
      </p>
      <ol>
        <li>
          <strong>Confiança de sigilo:</strong>
          <p>
            A participação necessita de confiança em todos. As informações que
            circulam aqui no portal devem permanecer nele apenas entre os
            participantes. Por isso, não compartilhe o conteúdo com pessoas de
            fora.
          </p>
        </li>

        <li>
          <strong>Nosso assunto é trabalho:</strong>
          <p>
            Não serão permitidos debates sobre temas não relacionados ao
            trabalho. Exemplos: política, religião, questões sociais, spam,
            links e venda de produtos e serviços irrelevantes.
          </p>
        </li>

        <li>
          <strong>É bom conviver com gente educada!:</strong>
          <p>
            É proibido o uso de ofensas, palavrões, posts provocativos e
            irônicos, assim como discursos de ódio. Não serão permitidas
            mensagens de intolerância religiosa, política, cultural, racial, de
            orientação sexual ou sobre qualquer outro tema que seja ofensivo ao
            próximo.
          </p>
        </li>

        <li>
          <strong>Nada de Indicações:</strong>
          <p>
            É proibida a solicitação de recomendações de vendas e vendedores,
            além de divulgação de vagas de trabalho.
          </p>
        </li>

        <li>
          <strong>Fique atento:</strong>
          <p>
            O integrante que desrespeitar as regras poderá ter sua postagem
            apagada e receber advertência.
          </p>
        </li>

        <li>
          <strong>Uso de imagem liberada:</strong>
          <p>
            As imagens postadas poderão ser utilizadas pela empresa, sem aviso
            prévio.
          </p>
        </li>

        <li>
          <strong>Resgate indevido de TIM Coins:</strong>
          <p>
            Caso o usuário não possua saldo de TIM Coins em seu extrato e mesmo
            assim realize diversos resgates de prêmios indevidamente, permitidos
            através de alguma instabilidade sistêmica (identificada pela equipe
            de TI/Fraude), os pedidos serão cancelados e a conduta inadequada do
            participante, será analisada pela equipe interna da TIM.
          </p>
        </li>

        <li>
          <strong>Expiração de TIM Coins:</strong>
          <p>
            A pontuação conquistada tem validade de 12 meses a partir da
            disponibilização dos TIM Coins no extrato.
            <br />
            Ao ser inativado no Pra Conectar, você receberá no e-mail secundário
            cadastrado, todas as orientações para resgatar TIM Coins pendentes
            (se houver). O prazo para esse resgate é de 30 dias a partir da data
            em que ficou inativo.
          </p>
        </li>
      </ol>
      <p>
        Tem alguma dúvida? Use o nosso chat para pedir ajuda sempre que precisar{' '}
        <span role="img" aria-label="rosto feliz">
          😊
        </span>
      </p>
    </Modal>
  );
};
