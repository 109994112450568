import { useState, useEffect, useMemo } from 'react';
import { useClient } from '../../autogenerated/client/client';
import {
  SortFindManyPinInput,
  EnumMaterialKind
} from '../../autogenerated/client/types';

import { KindFavoritos } from './InnerPageDataFavoritos';
import { MenuCategories } from 'config/menuCategories';
import { fragmentMaterialCard, fragmentCampaignCard } from 'utils/fragmentCard';

export function useFavoritos(
  sort: SortFindManyPinInput,
  kind: string,
  userId: string
) {
  const pinPagination = useClient('PinPagination');
  const [items, setItems] = useState<any>();
  const [materials, setMaterials] = useState<any>();
  const [incentiveCampaigns, setIncentiveCampaigns] = useState<any>();

  useEffect(() => {
    let kindAux: any;
    if (kind === MenuCategories.video) kindAux = EnumMaterialKind.Video;

    pinPagination
      .fetch({
        appendToFragment: fragmentFavorite(kind),
        variables: {
          perPage: 200,
          page: 1,
          filter: {
            owner: userId,
            kind: kindAux
          },
          sort: sort
        }
      })
      .then((ctx) => {
        const resultItems = ctx.result?.items!;

        const materialsItems = resultItems
          ?.filter((item) => item?.material)
          .map((item) => item?.material);

        const campaignsItems = resultItems
          ?.filter((item) => item?.campaign)
          .map((item) => item?.campaign);

        setItems(resultItems);
        setMaterials(materialsItems);
        setIncentiveCampaigns(campaignsItems);
      });
  }, [sort, kind]);

  return {
    items,
    materials,
    incentiveCampaigns,
    isLoading: pinPagination.loading
  };
}

export function useSelectsOrders(setKind: Function, setSort: Function) {
  const selectsOrders = useMemo(() => {
    return [
      {
        defaultValue: 'Ordenar Por',
        action: (value: SortFindManyPinInput) => {
          setSort(value);
        },
        options: [
          {
            name: 'Mais antigo',
            value: SortFindManyPinInput.IdAsc
          },
          {
            name: 'Mais recente',
            value: SortFindManyPinInput.IdDesc
          }
        ]
      },
      {
        defaultValue: 'Categoria do menu',
        action: (value: KindFavoritos) => {
          setKind(value);
        },
        options: [
          {
            name: 'Mobile & ICT',
            value: MenuCategories.mobileICT
          },
          {
            name: 'Processos',
            value: MenuCategories.processos
          },
          {
            name: 'Para o Cliente',
            value: MenuCategories.paraOCliente
          },
          {
            name: 'Vídeos',
            value: MenuCategories.video
          }
        ]
      }
    ];
  }, []);

  return selectsOrders;
}

export const fragmentFavorite = (category: string | undefined) => {
  return category
    ? `
      items {
        createdAt
        material(filter: {inCategories: ["${category}"]}) {
          ${fragmentMaterialCard}
        }
        ${
          category === 'menu:mobile_e_ict'
            ? `campaign {${fragmentCampaignCard}}`
            : ''
        }
      }
    `
    : `
      items {
        createdAt
        material {
          ${fragmentMaterialCard}
        }
        campaign {
          ${fragmentCampaignCard}
        }
      }
    `;
};
