import React from 'react';
import { User } from 'autogenerated/client/types';
import { useCurrentUser } from '@digi-tim-19/components';
import { useClient } from 'autogenerated/client/client';
import { ProfileSimulationAlert } from 'components/ProfileSimulator/ProfileSimulationAlert';
import { useHistory, useLocation } from 'react-router';
import { Logo } from './Logo';
import { RedeemPoints } from './Redeempoints';
import { MiniUserProfile } from './UserProfile';
import { BellIcon } from './BellIcon';
import { SearchInput } from './SearchInput';

import {
  ContainerTopBar,
  ContentTopBar,
  DivProfileAlert,
  HeaderContainer,
  HeaderContainerItems,
  SVGContainer,
  SVGText,
  SearchWrapper,
  BreadcrumbContainer
} from './styles';
import { TourSection } from 'providers/TourProvider';
import { Icon } from 'antd';
import { colors } from '@digi-tim-19/theme';

interface Props {
  breadcrumb?: React.ReactNode;
  onSelect?: (term: string) => any;
}

export const Header = ({ breadcrumb, onSelect }: Props) => {
  const user = useCurrentUser().result as User;
  const ReconheceUserBalance = useClient('ReconheceUserBalance', {
    fetchOnMount: true
  });
  const history = useHistory();
  const location = useLocation();
  const total = ReconheceUserBalance.result?.balance;

  const handleGoBack = () => {
    if (location.pathname !== '/') {
      history.go(-1);
    }
  };

  return (
    <>
      <DivProfileAlert>
        <ProfileSimulationAlert />
      </DivProfileAlert>

      <HeaderContainer>
        <Logo />
        <TourSection selector="tour-profile-menu" />
        <HeaderContainerItems className="tour-profile-menu">
          <div className="style">
            <RedeemPoints />
            <MiniUserProfile
              id={user?._id}
              name={user?.name ?? ''}
              profession={user?.roleGroup?.name as string}
              points={total || 0}
              img={user?.avatar?.signedUrl ?? ''}
              online={true}
            />
          </div>
          <BellIcon notifications={user?.notifications} />
        </HeaderContainerItems>
      </HeaderContainer>

      <ContainerTopBar>
        <ContentTopBar>
          {breadcrumb && (
            <SVGContainer onClick={handleGoBack}>
              <Icon
                type="left-circle"
                theme="filled"
                style={{
                  fontSize: '20px',
                  color: colors.blue
                }}
              />
              <SVGText>Voltar</SVGText>
            </SVGContainer>
          )}
          <BreadcrumbContainer>{breadcrumb && breadcrumb}</BreadcrumbContainer>
        </ContentTopBar>
        <SearchWrapper className="sarchBar">
          <SearchInput onSelect={onSelect} />
        </SearchWrapper>
      </ContainerTopBar>
    </>
  );
};
